import { Action } from '../../services/model/Action';
import { CACHE_MEDIA_OBJECT } from '../actions/actionTypes';
import { Status } from '../utillity';

const initialState: any = {
  cachedObjects: {}
};

const mediaObjectReducer = (state = initialState, action: Action): any => {
  const {type, payload} = action;

  if (type === CACHE_MEDIA_OBJECT) {
    const { imageUri, base64 } = payload;
    return {
      ...state,
      status: Status.SUCCEESS,
      cachedObjects: { ...state.cachedObjects, ...{[imageUri]: base64} },
    };
  }

  return state;
};

export default mediaObjectReducer;
