import jwt_decode from 'jwt-decode';

import {NotificationService, Variant} from "../../services/NotificationService";
import {createAccount} from "../actions/accounts";
import {authSuccess} from "../actions/auth";
import {enqueueSnackbar} from "../actions/notifications";
import {createRolesSucces} from "../actions/roles";
import LocalStorageService from "../LocalStorageServices";
import {AppDispatch} from "../store";
import {Status} from "../utillity";
import loadAgency from "./agency";

// eslint-disable-next-line import/prefer-default-export,@typescript-eslint/explicit-module-boundary-types
export const setUser = (token: string, account: any) => async (
  dispatch: AppDispatch
): Promise<any> => {

  const accessToken: any = jwt_decode(token);

  const authData = {
    authenticated: true,
    username: accessToken.username,
    error: null,
    status: Status.IDLE,
  };
  const notification = NotificationService.create(
    'Successful Login',
    Variant.INFO
  );

  dispatch(createAccount(account));

  LocalStorageService.setLoggedAccountId(account.id);
  if (account?.team) {
    LocalStorageService.setLoggedAccountTeamId(account?.team[0]?.id);
  }
  LocalStorageService.setLoggedAccountTimezone(account?.timezone);

  dispatch(enqueueSnackbar(notification));
  dispatch(authSuccess(authData));
  dispatch(createRolesSucces([...account?.roles]));
  dispatch<any>(loadAgency());
};
