import { SnackbarKey, VariantType } from 'notistack';

import { ENQUEUE_SNACKBAR, REMOVE_SNACKBAR } from '../actions/actionTypes';

export interface NotificationState {
  key: SnackbarKey;
  message?: string;
  dismissed: boolean;
  options: Options;
}

interface Options {
  variant: VariantType;
  onClose: any;
}

const initialState = { isLoading: false, data: <NotificationState[]>[] };

export const notificationsReducer = (state = initialState, action: { type: string, payload: any }): any => {
  const { type, payload } = action;

  switch (type) {
    case ENQUEUE_SNACKBAR: {
      return {
        ...state,
        data: state.data.concat(payload),
      };
    }
    case REMOVE_SNACKBAR: {
      const { key } = payload;
      return {
        ...state,
        data: state.data.filter((notification) => notification.key !== key),
      };
    }
    default:
      return state;
  }
};
