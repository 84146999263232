import { Action } from '../../services/model/Action';
import * as actionTypes from '../actions/actionTypes';
import LocalStorageService from '../LocalStorageServices';
import { Status } from '../utillity';

export interface AuthState {
  authenticated: boolean;
  username: string;
  error: any;
  status: Status;
}

const initialState: AuthState = {
  authenticated: !!LocalStorageService.getAccessToken(),
  username: '',
  error: null,
  status: Status.IDLE,
};

const authStart = (state: AuthState) => ({
  ...state,
  ...{ error: null, status: Status.LOADING },
});

const authSuccess = (state: AuthState, payload: any) => ({
  ...state,
  ...{
    authenticated: true,
    username: payload.username,
    error: null,
    status: Status.SUCCEESS,
  },
});

const authFail = (state: AuthState, error: any) => ({
  ...state,
  ...{
    error,
    status: Status.FAILED
  },
});

const authSignout = (state: AuthState) => ({
  ...state,
  ...initialState
});

const auth = (state: AuthState = initialState, action: Action): any => {
  const { type, payload, error } = action;

  switch (type) {
    case actionTypes.AUTH_START:
      return authStart(state);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, payload);
    case actionTypes.AUTH_FAIL:
      return authFail(state, error);
    case actionTypes.AUTH_SIGNOUT:
      return authSignout(state);
    default:
      return state;
  }
};

export default auth;
