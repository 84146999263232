import * as yup from 'yup';
// Validation schema
const ChangePasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required('No password provided.')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(/(?=.*[0-9])/, 'Password must contain a number.'),
  verifyPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

export default ChangePasswordSchema;
