import { VariantType } from 'notistack';

import { Notification } from './model/Notification';

export const NotificationService = {
  create: (message: string, variant: VariantType): Notification => ({
    key: new Date().getTime() + Math.random(),
    message,
    options: {
      variant,
    },
  }),
};

export enum Variant {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  DEFAULT = 'default',
}
