import PrivateNote from '../../services/model/PrivateNote';
import {
  CREATE_PRIVATE_NOTE,
  EDIT_PRIVATE_NOTE,
  LOAD_PRIVATE_NOTES_FAILURE,
  LOAD_PRIVATE_NOTES_IN_PROGRESS,
  LOAD_PRIVATE_NOTES_SUCCESS,
  REMOVE_PRIVATE_NOTE,
} from '../actions/actionTypes';
import { InitialState, Status } from '../utillity';

const initialState: InitialState<PrivateNote[]> = {
  data: <PrivateNote[]>[],
  status: Status.IDLE,
  error: null,
};

const privateNotesReducer = (state = initialState, action: { type: string, payload: any }): any => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_PRIVATE_NOTE: {
      const { privateNote } = payload;
      return {
        ...state,
        data: state.data.concat(privateNote),
      };
    }
    case EDIT_PRIVATE_NOTE: {
      const { privateNote: editPrivateNote } = payload;
      return {
        ...state,
        data: state.data.map((privateNote) => {
          if (privateNote.id === editPrivateNote.id) {
            return editPrivateNote;
          }
          return privateNote;
        }),
      };
    }
    case REMOVE_PRIVATE_NOTE: {
      const { privateNoteId } = payload;
      return {
        ...state,
        data: state.data.filter(
          (privateNote) => privateNote.id !== privateNoteId
        ),
      };
    }
    case LOAD_PRIVATE_NOTES_SUCCESS: {
      const { privateNotes } = payload;
      return {
        ...state,
        status: Status.SUCCEESS,
        data: privateNotes,
      };
    }
    case LOAD_PRIVATE_NOTES_IN_PROGRESS:
      return {
        ...state,
        status: Status.LOADING,
      };
    case LOAD_PRIVATE_NOTES_FAILURE: {
      return {
        ...state,
        status: Status.FAILED,
      };
    }
    default:
      return state;
  }
};

export default privateNotesReducer;
