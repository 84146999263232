import Teams from '../../services/api/Team';
import { loadTeamFailure, loadTeamSuccess } from '../actions/teams';
import { AppDispatch } from '../store';

const loadTeam = (id: number) => async (dispatch: AppDispatch): Promise<any> => {
  try {
    const response = await Teams.single(id);
    dispatch<any>(loadTeamSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch<any>(loadTeamFailure());
    return error;
  }
};

export default loadTeam;
