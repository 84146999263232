import Accounts from '../../services/api/Account';
import Agency from '../../services/api/Agency';
import MediaObjects from '../../services/api/MediaObject';
import {
  NotificationService,
  Variant,
} from '../../services/NotificationService';
import { AgencyUpdateRequest } from '../../services/request/AgencyUpdateRequest';
import {
  loadAgencyFailure,
  loadAgencySuccess,
  updateAgencyFailure,
  updateAgencySuccess,
} from '../actions/agency';
import { enqueueSnackbar } from '../actions/notifications';
import { AppDispatch } from '../store';

const loadAgency = () => async (dispatch: AppDispatch): Promise<void> => {
  try {
    const agency = await Accounts.getAgency();
    dispatch(loadAgencySuccess(agency.data));
  } catch (error) {
    dispatch(loadAgencyFailure());
  }
};

export const updateAgency = (
  id: number, 
  data: Partial<AgencyUpdateRequest>,
  uploaded?: boolean
  ) => async (
  dispatch: AppDispatch
): Promise<void> => {
  try {

    const mediaObjectResponse: any = uploaded
      ? await MediaObjects.create(data.imageFormData)
      : data.logo;
    
    const requestInstance: Partial<AgencyUpdateRequest> = {
      fullName: data.fullName,
      name: data.name,
    };
    if (mediaObjectResponse) {
      requestInstance.logo = `v1/media_objects/${mediaObjectResponse.data.id}`;
    }
    const response = await Agency.patch(id, requestInstance);
    dispatch(updateAgencySuccess(response.data));

    const notification = NotificationService.create(
      `Agency update success`,
      Variant.SUCCESS
    );
    dispatch(enqueueSnackbar(notification));
  } catch (e) {
    const notification = NotificationService.create(
      `Agency update failed with error:  ${e}!`,
      Variant.ERROR
    );
    dispatch(updateAgencyFailure());
    dispatch(enqueueSnackbar(notification));
  }
};

export default loadAgency;
