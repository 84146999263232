import BackOffice from "../../services/api/BackOffice";
import {NotificationService, Variant} from "../../services/NotificationService";
import {enqueueSnackbar} from "../actions/notifications";
import {AppDispatch} from "../store";

export const getBillingPortal = (id: string) => async (
  dispatch: AppDispatch
  // eslint-disable-next-line consistent-return
): Promise<any> => {
  try {
    const response = await BackOffice.get(id);
    return response.data;
  } catch (err) {
    const notification = NotificationService.create(
      `Billing information failed with error: ${err}!`,
      Variant.ERROR
    );
    dispatch(enqueueSnackbar(notification));
  }
};

export default getBillingPortal;
