import {Helmet} from "react-helmet";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import './App.scss';
import {useLocation} from "react-router-dom";

import classNames from 'classnames';

import Snackbar from './components/Snackbar/Snackbar';
import Layout from './containers/PageLayout/Layout/Layout';
import {changePageTitle} from "./shared/utility";
import {getAgency} from "./store/selectors/agency";
import {
  isAddLabelModalOpen,
  isClientModalOpen,
  isCreateLabelModalOpen,
  isDeleteRequestTypeModalOpen,
  isImagePreviewModalOpen,
  isInvitationModalOpen,
  isModalOpen,
  isNavModalOpen,
  isRequestDetailModalOpen,
  isRequestFormPreviewModalOpen,
  isSubmitRequestConfirmationModalOpen,
  isTimeLoggingModalOpen, isToastMessageOpen,
} from './store/selectors/modal';
import { toggleNav } from './store/thunks/modal';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const agency = useSelector((state) => getAgency(state));
  const isOpen = useSelector((state) => isModalOpen(state), shallowEqual);
  const isClientConfirmationOpen = useSelector(
    (state) => isClientModalOpen(state),
    shallowEqual
  );
  const isInvitationOpen = useSelector((state) => isInvitationModalOpen(state));
  const isNavOpen: any = useSelector((state) => isNavModalOpen(state));
  const isRequestDetailOpen = useSelector((state) =>
    isRequestDetailModalOpen(state)
  );
  const isTimeLoggingOpen = useSelector((state) =>
    isTimeLoggingModalOpen(state)
  );
  const isCreateLabelOpen = useSelector((state) =>
    isCreateLabelModalOpen(state)
  );

  const isDeleteRequestTypeOpen = useSelector((state) =>
    isDeleteRequestTypeModalOpen(state)
  );

  const isAddLabelOpen = useSelector((state) => isAddLabelModalOpen(state));
  const isRequestFormPreviewOpen = useSelector((state) =>
    isRequestFormPreviewModalOpen(state)
  );

  const isSubmitRequestConfirmationOpen = useSelector((state) =>
    isSubmitRequestConfirmationModalOpen(state)
  );

  const isImagePreviewOpen = useSelector((state) =>
    isImagePreviewModalOpen(state));

  const isToastNotificationOpen = useSelector((state) =>
    isToastMessageOpen(state));

  return (
    <div
      className={classNames('page', {
        overlay:
          isOpen ||
          isClientConfirmationOpen ||
          isInvitationOpen ||
          isNavOpen ||
          isRequestDetailOpen ||
          isDeleteRequestTypeOpen ||
          isRequestFormPreviewOpen,
        'overlay-secondary':
          isTimeLoggingOpen ||
          isCreateLabelOpen ||
          isAddLabelOpen ||
          isSubmitRequestConfirmationOpen ||
          isImagePreviewOpen ||
          isToastNotificationOpen
      })}
      onClick={() => (isNavOpen ? dispatch(toggleNav(false)) : null)}
      onKeyUp={() => (isNavOpen ? dispatch(toggleNav(false)) : null)}
      role="button"
      tabIndex={!isRequestDetailOpen ? 0 : -1}
    >
      <Helmet>
          <meta
            content={changePageTitle(location.pathname, agency?.fullName)}
            property='og:title'
            />
        <title> {changePageTitle(location.pathname, agency?.fullName)} </title>
      </Helmet>
      <Snackbar />
      <Layout />
    </div>
  );
};

export default App;
