import {
  TOGGLE_ADD_LABEL_MODAL,
  TOGGLE_CLIENT_MODAL,
  TOGGLE_CREATE_LABEL_MODAL,
  TOGGLE_DELETE_REQUEST_TYPE_MODAL,
  TOGGLE_IMAGE_PREVIEW_MODAL,
  TOGGLE_INVITATION_MODAL,
  TOGGLE_MODAL,
  TOGGLE_NAV_MODAL,
  TOGGLE_REQUEST_DETAIL_MODAL,
  TOGGLE_REQUEST_FORM_PREVIEW_MODAL,
  TOGGLE_SUBMIT_REQUEST_CONFIRMATION_MODAL,
  TOGGLE_SUBMIT_REQUEST_MODAL,
  TOGGLE_TIME_LOGGING_MODAL, TOGGLE_TOAST_MESSAGE_MODAL,
} from './actionTypes';

export const toggle = (isOpen: boolean): any => ({
  type: TOGGLE_MODAL,
  payload: isOpen,
});

export const toggleClientModal = (isOpen: boolean): any => ({
  type: TOGGLE_CLIENT_MODAL,
  payload: isOpen,
});
export const toggleInvitation = (isInvitationOpen: boolean): any => ({
  type: TOGGLE_INVITATION_MODAL,
  payload: isInvitationOpen,
});
export const toggleNavigation = (isNavOpen: boolean): any => ({
  type: TOGGLE_NAV_MODAL,
  payload: isNavOpen,
});

export const toggleRequestDetail = (isRequestDetailOpen: boolean): any => ({
  type: TOGGLE_REQUEST_DETAIL_MODAL,
  payload: isRequestDetailOpen,
});

export const toggleTimeLogging = (isTimeLoggingOpen: boolean): any => ({
  type: TOGGLE_TIME_LOGGING_MODAL,
  payload: isTimeLoggingOpen,
});

export const toggleDeleteRequestType = (
  isDeleteRequestTypeOpen: boolean
): any => ({
  type: TOGGLE_DELETE_REQUEST_TYPE_MODAL,
  payload: isDeleteRequestTypeOpen,
});

export const toggleSubmitRequest = (isSubmitRequestOpen: boolean): any => ({
  type: TOGGLE_SUBMIT_REQUEST_MODAL,
  payload: isSubmitRequestOpen,
});

export const toggleRequestFormPreview = (
  isSubmitRequestOpen: boolean
): any => ({
  type: TOGGLE_REQUEST_FORM_PREVIEW_MODAL,
  payload: isSubmitRequestOpen,
});

export const toggleCreateLabel = (isCreateLabelOpen: boolean): any => ({
  type: TOGGLE_CREATE_LABEL_MODAL,
  payload: isCreateLabelOpen,
});

export const toggleAddLabel = (isAddLabelOpen: boolean): any => ({
  type: TOGGLE_ADD_LABEL_MODAL,
  payload: isAddLabelOpen,
});

export const toggleSubmitRequestConfirmationModal = (isSubmitRequestConfirmationOpen: boolean): any => ({
  type: TOGGLE_SUBMIT_REQUEST_CONFIRMATION_MODAL,
  payload: isSubmitRequestConfirmationOpen
});

export const togglePreviewImageModal = (isImagePreviewOpen: boolean): any => ({
  type: TOGGLE_IMAGE_PREVIEW_MODAL,
  payload: isImagePreviewOpen
});

export const toggleToastMessageModal = (isToastMessageOpen: boolean): any => ({
  type: TOGGLE_TOAST_MESSAGE_MODAL,
  payload: isToastMessageOpen
});
