import {
    LOAD_PROGRESS_FAILURE,
    LOAD_PROGRESS_IN_PROGRESS,
    LOAD_PROGRESS_SUCCESS,
} from '../actions/actionTypes';
import { InitialState, Status } from "../utillity";

const initialState: InitialState<[]> = {
    data: <[]>[],
    status: Status.IDLE,
    error: null,
};

const progressReducer = (state = initialState, action: { type: string, payload: any }): any => {
    const {type, payload} = action;

    switch (type) {
        case LOAD_PROGRESS_IN_PROGRESS: {
            return {
                ...state,
                status: Status.LOADING
            };
        }

        case LOAD_PROGRESS_SUCCESS: {
            const { progressData } = payload;
            return {
              ...state,
              status: Status.SUCCEESS,
              data: progressData,
            };
        }

        case LOAD_PROGRESS_FAILURE: {
            return {
              ...state,
              status: Status.FAILED,
            };
        }
        default:
            return state;
    }
};

export default progressReducer;