import { Action } from '../../services/model/Action';
import { Label } from '../../services/model/Request';
import {
  CREATE_LABEL_FAILURE,
  CREATE_LABEL_IN_PROGRESS,
  CREATE_LABEL_SUCCESS,
  LOAD_LABELS_FAILURE,
  LOAD_LABELS_IN_PROGRESS,
  LOAD_LABELS_SUCCESS,
  REMOVE_LABEL,
  UPDATE_LABEL,
} from '../actions/actionTypes';
import { InitialState, Status } from '../utillity';

const initialState: InitialState<Label[]> = {
  data: <Label[]>[],
  status: Status.IDLE,
  error: null,
};

const labelsReducer = (state = initialState, action: Action): any => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_LABELS_SUCCESS: {
      const { labels } = payload;
      return {
        ...state,
        status: Status.SUCCEESS,
        data: labels,
      };
    }
    case LOAD_LABELS_FAILURE: {
      return {
        ...state,
        status: Status.FAILED,
        error: 'Load labels failed.',
      };
    }
    case LOAD_LABELS_IN_PROGRESS: {
      return {
        ...state,
        status: Status.LOADING,
      };
    }
    case CREATE_LABEL_SUCCESS: {
      const { label } = payload;
      return {
        ...state,
        data: state.data.concat(label),
        status: Status.SUCCEESS,
      };
    }
    case CREATE_LABEL_IN_PROGRESS: {

      return {
        ...state,
        status: Status.LOADING,
      };
    }
    case CREATE_LABEL_FAILURE: {
      return {
        ...state,
        status: Status.FAILED,
        error: 'Create label failed',
      };
    }
    case UPDATE_LABEL: {
      const { label: updateLabel } = payload;
      return {
        ...state,
        data: state.data.map((label) => {
          if (label.id === updateLabel.id) {
            return updateLabel;
          }
          return label;
        }),
      };
    }
    case REMOVE_LABEL: {
      const { id } = payload;
      return {
        ...state,
        data: state.data.filter(
          (label) => label.id !== id
        ),
      };
    }
    default:
      return state;
  }
};

export default labelsReducer;
