import { Action } from '../../services/model/Action';
import { FormField } from '../../services/model/FormField';
import {
  LOAD_FORM_FIELDS_FAILURE,
  LOAD_FORM_FIELDS_IN_PROGRESS,
  LOAD_FORM_FIELDS_SUCCESS
} from '../actions/actionTypes';
import {InitialState, Status} from '../utillity';

const initialState: InitialState<FormField[]> = {
  data: <FormField[]>[],
};

const formFieldsReducer = (state = initialState, action: Action): any => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_FORM_FIELDS_IN_PROGRESS: {
      return {
        ...state,
        status: Status.LOADING,
      };
    }
    case LOAD_FORM_FIELDS_SUCCESS: {
      const { formFields } = payload;
      return {
        ...state,
        data: formFields
      };
    }
    case LOAD_FORM_FIELDS_FAILURE: {
      return {
        ...state,
        error: 'Load form fields failed',
      };
    }
    default:
      return state;
  }
};

export default formFieldsReducer;
