import { exportFileName } from "../../shared/utility";
import { cacheMediaObject } from '../../store/actions/mediaObjects';
import store from '../../store/store';
import MediaObject from '../api/MediaObject';

const ImageHandlerService = {
  getFormDataInstance: (file: File | undefined): FormData => {
    const formData = new FormData();
    if (file) {
      formData.append('file', file, file.name);
    }

    return formData;
  },

  setPreview: async (
    image: File | undefined,
    imageUri: string | null,
    callback: (params: {preview: string, name: string}) => void,
    size?: 'thum' | 'mid',
    shouldBeCached?: false | true
  ): Promise<void> => {
    const reader = new FileReader();
    const state: any = store.getState();
    if (image) {
      reader.onloadend = () => {
        callback({
          preview: reader.result as string,
          name: image.name
        });
      };
      reader.readAsDataURL(image);
    } else if (imageUri) {
      if (shouldBeCached &&
        state?.mediaObjects?.cachedObjects[`${imageUri}-${size}`])
      {
        const {res} = state.mediaObjects.cachedObjects[`${imageUri}-${size}`];
        callback({
          preview: res,
          name: imageUri
        });
      }
      else if (shouldBeCached && !state?.mediaObjects?.cachedObjects[`${imageUri}-${size}`]) {
        MediaObject.single(encodeURIComponent(imageUri), size).then((res: any) => {
          callback({preview: res.res, name: imageUri});
          store.dispatch(cacheMediaObject(`${imageUri}-${size}`, res));
        });
      }
      else {
        MediaObject.single(encodeURIComponent(imageUri), size).then((res: any) => {
          callback({preview: res.res, name: imageUri});
        });
      }
    }
  },

  download: async (filePath: string): Promise<void> => {
    const response = await MediaObject.single(encodeURIComponent(filePath));
    const base64Response = await fetch(response.res);
    const blob = await base64Response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    const fileName = exportFileName(filePath);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  },

  openInNewTab: async (filePath: string): Promise<void> => {
    const response = await MediaObject.single(encodeURIComponent(filePath));
    const base64Response = await fetch(response.res);
    const blob = await base64Response.blob();
    const url = window.URL.createObjectURL(blob);
    const pdfWindow = window.open();
    // @ts-ignore
    pdfWindow.location.href = url;
  },

  readFiles: (file: File): Promise<any> =>
    new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onloadend = () => {
        const formData = ImageHandlerService.getFormDataInstance(file);
        resolve(formData);
      };
      fr.onerror = () => {
        reject(fr);
      };
      fr.readAsDataURL(file);
    })
};

export default ImageHandlerService;
