import TimeLog from '../../services/model/TimeLog';
import {
  CREATE_TIME_LOG,
  EDIT_TIME_LOG,
  LOAD_TIME_LOGS_FAILURE,
  LOAD_TIME_LOGS_IN_PROGRESS,
  LOAD_TIME_LOGS_SUCCESS,
  REMOVE_TIME_LOG,
} from '../actions/actionTypes';
import { InitialState, Status } from '../utillity';

const initialState: InitialState<TimeLog[]> = {
  data: <TimeLog[]>[],
  status: Status.IDLE,
  error: null,
};

const timeLogsReducer = (state = initialState, action: { type: string, payload: any }): any => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_TIME_LOG: {
      const { timeLog } = payload;
      return {
        ...state,
        data: [timeLog, ...state.data],
      };
    }
    case EDIT_TIME_LOG: {
      const { timeLog: editTimeLog } = payload;
      return {
        ...state,
        data: state.data.map((timeLog) => {
          if (timeLog.id === editTimeLog.id) {
            return editTimeLog;
          }
          return timeLog;
        }),
      };
    }
    case REMOVE_TIME_LOG: {
      const { timeLogId } = payload;
      return {
        ...state,
        data: state.data.filter((timeLog) => timeLog.id !== timeLogId),
      };
    }
    case LOAD_TIME_LOGS_SUCCESS: {
      const { timeLogs } = payload;
      return {
        ...state,
        status: Status.SUCCEESS,
        data: timeLogs,
      };
    }
    case LOAD_TIME_LOGS_IN_PROGRESS:
      return {
        ...state,
        status: Status.LOADING,
      };
    case LOAD_TIME_LOGS_FAILURE: {
      return {
        ...state,
        status: Status.FAILED,
      };
    }
    default:
      return state;
  }
};

export default timeLogsReducer;
