export interface ModalState {
  isOpen: boolean;
  isClientOpen: boolean;
  isNavOpen: boolean;
  isInvitationOpen: boolean;
  isRequestDetailOpen: boolean;
  isTimeLoggingOpen: boolean;
  isDeleteRequestTypeOpen: boolean;
  isRequestFormPreviewOpen: boolean;
  isSubmitRequestConfirmationOpen: boolean;
  isImagePreviewOpen: boolean;
  isToastMessageOpen: boolean
}
const initialState: ModalState = {
  isOpen: false,
  isClientOpen: false,
  isNavOpen: false,
  isInvitationOpen: false,
  isRequestDetailOpen: false,
  isTimeLoggingOpen: false,
  isDeleteRequestTypeOpen: false,
  isRequestFormPreviewOpen: false,
  isSubmitRequestConfirmationOpen: false,
  isImagePreviewOpen: false,
  isToastMessageOpen: false
};

export const modalReducer = (state = initialState, action: { type: string, payload: any }): any => {
  const { type, payload } = action;

  switch (type) {
    case 'TOGGLE_MODAL':
      return { isOpen: payload };
    case 'TOGGLE_CLIENT_MODAL':
      return { isClientOpen: payload };
    case 'TOGGLE_INVITATION_MODAL':
      return { isInvitationOpen: payload };
    case 'TOGGLE_NAV_MODAL':
      return { isNavOpen: payload };
    case 'TOGGLE_REQUEST_DETAIL_MODAL':
      return { isRequestDetailOpen: payload };
    case 'TOGGLE_TIME_LOGGING_MODAL':
      return { isTimeLoggingOpen: payload };
    case 'TOGGLE_DELETE_REQUEST_TYPE_MODAL':
      return { isDeleteRequestTypeOpen: payload };
    case 'TOGGLE_SUBMIT_REQUEST_MODAL':
      return { isSubmitRequestOpen: payload };
    case 'TOGGLE_SUBMIT_REQUEST_CONFIRMATION_MODAL':
      return { isSubmitRequestConfirmationOpen: payload };
    case 'TOGGLE_REQUEST_FORM_PREVIEW_MODAL':
      return { isRequestFormPreviewOpen: payload };
    case 'TOGGLE_CREATE_LABEL_MODAL':
      return { isCreateLabelOpen: payload };
    case 'TOGGLE_ADD_LABEL_MODAL':
      return { isAddLabelOpen: payload };
    case 'TOGGLE_IMAGE_PREVIEW_MODAL':
      return { isImagePreviewOpen: payload };
    case 'TOGGLE_TOAST_MESSAGE_MODAL': {
      return {isToastMessageOpen: payload};
    }
    default:
      return state;
  }
};
