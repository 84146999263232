import { RefObject } from 'react';

import { Account } from '../../services/model/Account';
import Client from '../../services/model/Client';
import Comment from "../../services/model/Comment";
import { Label, Request, StatusFlag } from '../../services/model/Request';
import {RequestPriority, RequestType} from '../../services/model/RequestType';

export const DEFAULT_VALUE_ANY = 'Any';
export const DEFAULT_VALUE_CLIENT = 'All clients';
export const DEFAULT_VALUE_VIEW = 'List View';
export const DEFAULT_VALUE_ASSIGNEE = 'Any assignee';
export const NO_ASSIGNEE = 'Unassigned';
export const DEFAULT_FOLLOWED_BY_FLAG = false;
export const COMPLETED_TASKS_VALUE = 3;

export enum View {
  LIST = 'List View',
  KANBAN = 'Board View',
  CALENDAR = 'Calendar View',
}

export interface ChartViewType {
  chart: string
}

export enum ChartView {
  REQUEST_RECEIVED = 'Request Received',
  REQUEST_RESOLVED = 'Request Resolved',
  HOURS_LOGGED = 'Hours Logged',
  RESPONSE_TIME = 'Response Time'
}

export interface RequestProps {
  request: Request;
  openDetail: (request: Request, seenBy?: boolean) => void;
}
export interface RequestDetailProps {
  request: Request;
  setRequest: (request: Request) => void;
  isDetailModalOpen: boolean;
  filters: {[key: string]: any};
  newUpdates: boolean
}
export interface RequestDetailsProps {
  request: Request;
  elementWithScrollId?: string;
  isSecondModalOpen: (isOpen: boolean) => void;
  isRequestDetailsOpen?: boolean;
  onSetRequestDetailsRef?: (ref: RefObject<HTMLDivElement>) => void;
}
export interface CompanyDetailProps {
  request: Request;
}
export interface PrivateNoteProps {
  clientId: string;
}

export enum DateOption {
  DUE_DATE = 'Due Date',
  SUBMITED_DATE = 'Date Submited',
}

export interface DefaultFilterProps {
  defaultValue: any;
  selectedValue?: any;
  outerSelectedValue?: any;
  disabled?: boolean;
  elementWithScrollId?: string;
  setDefault?: boolean;
}
export interface SelectViewProps extends DefaultFilterProps {
  selectView: (view: string) => void;
  shouldHaveTabIndex?: boolean;
}
export interface StatusFilterProps extends DefaultFilterProps {
  selectFilterStatus: (status: StatusFlag) => void;
  statusFlags: any;
  isFilterOpen?: boolean;
}
export interface AssigneeFilterProps extends DefaultFilterProps {
  selectFilterAssignee: (filter: Account) => void;
  isFilterOpen?: boolean;
  isFilterDropdown?: boolean;
}
export interface AssigneeDropdownProps extends DefaultFilterProps {
  clientId: number;
  selectAssignee: (assignee: string) => void;
  isRequestDetailsOpen?: boolean;
}
export interface StatusDropdownProps extends DefaultFilterProps {
  selectStatus: (status: string) => void;
  isRequestDetailsOpen?: boolean;
}
export interface ClientFilterProps extends DefaultFilterProps {
  selectFilterClient: (client: Client) => void;
  isFilterOpen?: boolean;
  isFilterDropdown?: boolean;
}
export interface RequestTypeFilterProps extends DefaultFilterProps {
  selectFilterRequestType: (type: RequestType) => void;
  isFilterOpen?: boolean;
  isFilterDropdown?: boolean;
}
export interface RequestPriorityFilterProps extends DefaultFilterProps {
  selectFilterRequestPriority: (type: RequestPriority) => void;
  isFilterOpen?: boolean;
  isFilterDropdown?: boolean;
}
export interface LabelFilterProps extends DefaultFilterProps {
  selectFilterLabel: (labels: Label[]) => void;
  isFilterOpen?: boolean;
  isFilterDropdown?: boolean;
}
export interface SortByProps extends DefaultFilterProps {
  selectSort: (sort: {name: string, value: string | null}) => void;
  shouldHaveTabIndex?: boolean;
}
export interface DropdownProps extends DefaultFilterProps {
  classname: string;
  data: any[];
  title?: string;
  type?: string;
  renderIconFn?: (prop: string) => string;
  onSelect: (filter: string) => void;
  outerSelectedValue?: any;
  icon?: boolean;
  withSearch?: boolean;
  additionalItems?: any[];
  optionComponent?: any;
  custom?: boolean;
  hashTag?: boolean,
  hideDefaultOption?: boolean;
  hideSelected?: boolean;
  checkBox?: boolean;
  settings?: {
    key?: string;
    label?: string;
    value?: string;
  };
  hideSelectedMobile?: boolean;
  shouldHaveTabIndex? : boolean;
  isFilterDropdown?: boolean;
  isBatchAction?: boolean;
}
export interface BatchActionProps extends DefaultFilterProps {
  classname: string;
  data: any[];
  title?: string;
  type?: string;
  setAction: (object: any) => void;
}
export interface StatusBatchActionProps extends DefaultFilterProps {
  selectStatusBatchAction: (object: StatusFlag) => void;
}
export interface AssigneeBatchActionProps extends DefaultFilterProps {
  selectAssigneeBatchAction: (object: Account) => void;
  isFilterOpen?: boolean;
}
export interface StatusActionProps extends DefaultFilterProps {
  selectActionStatus: (status: StatusFlag) => void;
}
export interface LabelBatchActionProps extends DefaultFilterProps {
  selectLabelBatchAction: (object: Label[]) => void;
  isFilterOpen?: boolean;
}
export interface AdditionalFilterProps {
  additionalFilter: (filter?: string | boolean | undefined) => void;
  changeActive: (id: number | undefined) => void;
  id?: number;
  isActive: boolean;
  title: string;
  isRequestOpen?: boolean;
}

export interface AdditionalFiltersProps {
  assignedToUserFilter: (filter?: string | undefined) => void;
  clearAssigneeFilter: () => void;
  completedTasks: () => void;
  showAllFilter: () => void;
  enableFollowByUser: () => void;
  outerSelectTab?: number[];
  selectedView: string;
  isClientRole: boolean;
  isRequestOpen?: boolean;
}

export const existsAssignee = (
  name: string,
  filter: string,
  assignee?: string
): boolean =>
  DEFAULT_VALUE_ASSIGNEE === (assignee as string)
    ? true
    : name?.toLowerCase().includes(filter);

export const existsInArray = (arr: string[], filter: string): boolean => {
  if (filter) {
    return arr.map((name) => name.toLowerCase()).includes(filter);
  }
  return true;
};
export const followedByUser = (
  enabled: boolean,
  arr: number[],
  filter: number | null
): boolean => {
  const followed = filter ? !enabled || arr.includes(filter) : true;

  return followed;
};

export const countLowercasedFilter = (
  filter: string,
  defaultValue: string
): string => (filter === defaultValue ? '' : filter.toLowerCase());

export const TextareaReference = {
  textarea: HTMLInputElement,
};

export enum FilterDropdownTitle {
  STATUS = 'Status:',
  ASSIGNEE = 'Assignee:',
  CLIENT = 'Client:',
  REQUEST_TYPE = 'Request Type:',
  REQ_PRIORITY = 'Priority Level:',
  LABEL = 'Label:',
  VIEW = 'View',
}

export interface SubmitRequestModalProps {
  isSubmitModalOpen: boolean;
}

export enum FilterDropdownID {
  STATUS = 'status-filter',
  ASSIGNEE = 'assigne-filter',
  CLIENT = 'client-filter',
  REQUEST_TYPE = 'type-filter',
  LABEL = 'label-filter',
  VIEW = 'view-filter',
}

const twoDigit = (n: number): string => (n < 10 ? `0${n}` : `${n}`);

export const convertTimeLog = (timeLog: number): string => {
  const num = timeLog;
  const hours = num / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  return `${rhours}:${twoDigit(rminutes)}`;
};
export const convertToTimeLog = (hours: number, minutes: number): number =>
  hours * 60 + minutes;
export const getHourFromTimeLog = (timeLog: number): number => {
  const num = timeLog;
  const hours = num / 60;
  const rhours = Math.floor(hours);
  return rhours;
};
export const getMinuteFromTimeLog = (timeLog: number): number => {
  const num = timeLog;
  const hours = num / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  return rminutes;
};
export const openedRequests = (statusFlags: StatusFlag[], showAll = true): string => {
  if (!showAll) {
   return  statusFlags.map((status, index) => {
      if (status.id !== 3)
        return  index === 0 ?
          `${status.id}` : `statusFlagId[]=${status.id}`;
      return '';
    }).join('&');
  }

  return statusFlags.map((status, index) =>
    index === 0 ?
    `${status.id}` : `statusFlagId[]=${status.id}`).join('&');
};
export type BatchDropdownsWithCheckbox = Label[];

export const isWatcher = (req: Request, loggedAccountID: number): boolean =>
  req?.watchers?.filter((w: any) => w.id === loggedAccountID).length > 0;

export const getCommentAuthor = (
  comments: Comment[]
): string =>
 `${comments[comments?.length-1]?.author?.firstName}
    ${comments[comments?.length-1]?.author?.lastName}`;

export const getLastCommentFile = (
   comments: Comment[]
): string | null => {
  const commentFiles =
    (comments && comments[comments?.length-1]?.commentFiles);

  return commentFiles ? commentFiles[commentFiles?.length - 1]?.mediaObject?.filePath : null;
};

export const getPrivateNotePlaceholder = (clientName: string): string =>
  `Add a note to keep track of important information about ${clientName}. ` +
  "Notes are automatically attached to all of their requests, " +
  "and are only visible to your team.";

export const getCommentBodyContent = (
  comments: Comment[]
): string =>
  comments[comments?.length-1]?.commentBody;

export const getTabIndexValue = (isRequestModalOpen: boolean, additionalReq?: boolean): number => {
  if(additionalReq === undefined)
    return !isRequestModalOpen ? 0 : -1;

  return !isRequestModalOpen && additionalReq ? 0 : -1;
};
