import {AccessRequestPermission} from "../../services/model/AccessRequestPermission";
import {Action} from "../../services/model/Action";
import {
  LOAD_ACCESS_REQUEST_PERMISSION_FAILURE,
  LOAD_ACCESS_REQUEST_PERMISSION_IN_PROGRESS,
  LOAD_ACCESS_REQUEST_PERMISSION_SUCCESS,
} from "../actions/actionTypes";
import {InitialState, Status} from "../utillity";

const initialState: InitialState<AccessRequestPermission[]> = {
  data: <AccessRequestPermission[]>[],
  status: Status.IDLE,
  error: null,
};

const accessRequestPermissionsReducer = (state = initialState, action: Action): any => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_ACCESS_REQUEST_PERMISSION_SUCCESS: {
      const { permissions } = payload;
      return {
        ...state,
        status: Status.SUCCEESS,
        data: permissions,
      };
    }
    case LOAD_ACCESS_REQUEST_PERMISSION_IN_PROGRESS:
      return {
        ...state,
        status: Status.LOADING,
      };
    case LOAD_ACCESS_REQUEST_PERMISSION_FAILURE:
      return {
        ...state,
        status: Status.FAILED,
      };
    default:
      return state;
  }
};

export default accessRequestPermissionsReducer;
