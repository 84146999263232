/* eslint-disable no-restricted-globals */
import {Suspense} from 'react';
import { useSelector} from 'react-redux';

import './Layout.scss';

import {
  isAgencyAdminRole,
  isAgencyRole,
  Roles,
} from '../../../shared/utility';
import { AuthState } from '../../../store/reducers/auth';
import {getAuthData} from '../../../store/selectors/auth';
import getRolesData from '../../../store/selectors/roles';
import Header from '../Header/Header';
import { ChildComponentProps, NavInfo } from '../RoutingAPI/Routes';
import Content from './Content';

const Layout: React.FC = () => {
  const authData: AuthState = useSelector((state) => getAuthData(state));
  const roles: Roles[] = useSelector((state) => getRolesData(state));

  const items: NavInfo[] = [
    {
      path: '/request',
      title: 'Requests',
      isHomePage: false,
    },
    {
      path: '/team',
      title: 'Team',
      isHomePage: true,
    },
  ];
  if (isAgencyRole(roles)) {
    items.splice(1, 0, {
      path: '/clients',
      title: 'Clients',
      isHomePage: false,
    });
  }
  if (isAgencyAdminRole(roles)) {
    items.splice(3, 0, {
      path: '/settings',
      title: 'Settings',
      isHomePage: false,
      childrenRoutes: [
        {
          path: '/settings/input-forms',
          title: 'Input Forms',
          isHomePage: false,
        },
        {
          path: '/settings/status-flags',
          title: 'Status Flags',
          isHomePage: false,
        },
        {
          path: '/settings/canned-responses',
          title: 'Canned Responses',
          isHomePage: false,
        },
        {
          path: '/settings/agency',
          title: 'Company Settings',
          isHomePage: false,
        }
      ]
    });
  }

  const navItemList: ChildComponentProps = { items };

  return (
    <>
      {authData.authenticated ? <Header items={navItemList.items} /> : null}
      <Suspense fallback={null}>
        <Content items={navItemList.items} />
      </Suspense>
    </>
  );
};

export default Layout;
