import { get, patch, post } from '../../config/api';
import { AccountChangePasswordRequest } from '../request/AccountChangePasswordRequest';
import { AccountEditRequest } from '../request/AccountEditRequest';
import { AccountLoginRequest } from '../request/AccountLoginRequest';
import { AccountResetPasswordRequest } from '../request/AccountResetPasswordRequest';
import { AccountSetUserRequest } from '../request/AccountSetUserRequest';

// API Endpoints
const ACCOUNTS_URL = '/accounts';
const ACCOUNTS_SET_USER_URL = '/accounts/set-user';

const ACCOUNTS_RESET_PASSWORD_URL = '/accounts/reset-password';
const ACCOUNTS_CHANGE_PASSWORD_URL = '/accounts/change-password';
const ACCOUNTS_CHECK_VALID_PASSWORD_URL =   '/accounts/check-valid-password';
const ACCOUNTS_LOGIN_URL = '/login_check';
const ACCOUNTS_GOOGLE_LOGIN_URL = '/accounts/google-login';
const AGENCY_URL = '/agencies/1';
const ACCESS_REQUEST_PERMISSIONS = '/access_request_permissions';
const UPDATE_ACCESS_REQUEST_PERMISSIONS = '/accounts/set-permission';

const Accounts = {
  index: (role: number | null): any => get(ACCOUNTS_URL, { params: { agencyRoles: role } }),
  singleByEmail: (email: string): any => get(`${ACCOUNTS_URL}?email=${email}`),
  login: (params: AccountLoginRequest): any =>
    post(`${ACCOUNTS_LOGIN_URL}${params.additionalCondition ? `?${params.additionalCondition}` : ''}`, params),
  googleLogin: (username: string): any =>
    post(ACCOUNTS_GOOGLE_LOGIN_URL, {username}),
  resetPassword: (params: AccountResetPasswordRequest): any =>
    post(ACCOUNTS_RESET_PASSWORD_URL, params),
  changePassword: (userId: number, params: AccountChangePasswordRequest): any =>
    patch(`${ACCOUNTS_CHANGE_PASSWORD_URL}/${userId}`, params),
  update: (id: number, params: AccountEditRequest): any =>
    patch(`${ACCOUNTS_URL}/${id}`, params),
  shallowRemove: (id: number, params: any): any =>
    patch(`${ACCOUNTS_URL}/${id}`, params),
  setUser: (params: AccountSetUserRequest): any =>
    post(ACCOUNTS_SET_USER_URL, params),
  getAgency: (): any => get(AGENCY_URL),
  checkPassword: (password: string): any => post(ACCOUNTS_CHECK_VALID_PASSWORD_URL, {password}),
  getPermissions: (): any => get(ACCESS_REQUEST_PERMISSIONS),
  updatePermissions: (id: number, params: any): any => patch(`${UPDATE_ACCESS_REQUEST_PERMISSIONS}/${id}`, params)
};

export default Accounts;
