/* eslint-disable jsx-a11y/label-has-associated-control */
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import '../ChangePassword/ChangePassword.scss';

import { AccountChangePasswordRequest } from '../../services/request/AccountChangePasswordRequest';
import ChangePasswordSchema from '../../services/validation/ChangePasswordSchema';
import { AppDispatch } from '../../store/store';
import {auth} from "../../store/thunks/auth";
import changePassword from '../../store/thunks/changePassword';

export interface IChangePasswordForm {
  password: string;
  verifyPassword: string;
}

const ActivateAccount: React.FC = () => {
  const location = useLocation();
  const { search } = location;
  const dispatch: AppDispatch = useDispatch();

  const userId: string | undefined = location.pathname.split('/').pop();
  const email = new URLSearchParams(search).get('email');
  const token = new URLSearchParams(search).get('token');

  const onSubmit = (data: AccountChangePasswordRequest) => {
    const requestInstance = {
      password: data.password,
      token,
      email,
    };

    if (userId) {
      dispatch<any>(changePassword(+userId, requestInstance))
        .then(() => {
          dispatch<any>(auth(email as string, data.password));
        });
    }
  };

  const { register, handleSubmit, errors } = useForm<IChangePasswordForm>({
    mode: 'all',
    resolver: yupResolver(ChangePasswordSchema),
  });

  return (
    <div className="region--content region--content--center" id="change-password-wrapper">
      <div className="page page--form page--profile">
        <div className="region__inner">
          <div className="form-header">
            <h1 className="page-title">Set a password</h1>
            <p className="p--desc border-bottom border-full">
              Please enter an appropriate password and verify it.
            </p>
          </div>
          <form
            className="form form--first-login"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="form__item form__item--password">
              <label htmlFor="password">Password</label>
              <input
                className="form--password"
                id="password"
                name="password"
                placeholder="Pick a strong password"
                ref={register}
                type="password"
              />
              {errors.password && (
                <p className="form-error">{errors.password.message}</p>
              )}
            </div>
            <div className="form__item form__item--password">
              <label htmlFor="verifyPassword">Verify password</label>
              <input
                className="form--password"
                id="verifyPassword"
                name="verifyPassword"
                placeholder="Retype password"
                ref={register}
                type="password"
              />
              {errors.verifyPassword && (
                <p className="form-error">{errors.verifyPassword.message}</p>
              )}
            </div>
            <div className="form__item form__actions align-buttons">
              <input
                className="form--submit button button--small button--red"
                type="submit"
                value="Save"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ActivateAccount;
