// Action creators.

import { Team } from '../../services/model/Team';
import {
  LOAD_TEAM_FAILURE,
  LOAD_TEAM_SUCCESS,
} from './actionTypes';

export const loadTeamSuccess = (team: Team): any => ({
  type: LOAD_TEAM_SUCCESS,
  payload: { team },
});

export const loadTeamFailure = (): any => ({
  type: LOAD_TEAM_FAILURE,
});
