/* eslint-disable import/prefer-default-export */
import {View} from "../../containers/Request/utility";
import { Request } from '../../services/model/Request';
import {
  CREATE_REQUEST_FAILURE,
  CREATE_REQUEST_IN_PROGRESS,
  CREATE_REQUEST_SUCCESS,
  EDIT_REQUEST, LOAD_REQUEST_FAILURE, LOAD_REQUEST_IN_PROGRESS, LOAD_REQUEST_SUCCESS,
  LOAD_REQUESTS_FAILURE,
  LOAD_REQUESTS_IN_PROGRESS,
  LOAD_REQUESTS_SUCCESS,
  REMOVE_REQUEST,
  UPDATE_CURRENT_PAGE,
  UPDATE_TOTAL_ITEMS,
  UPDATE_TOTAL_PAGES
} from '../actions/actionTypes';
import LocalStorageService from "../LocalStorageServices";
import { InitialState, Status } from '../utillity';

const initialState: InitialState<Request[]> & {
  creationStatus: Status,
  totalItems: number,
  totalPages: number,
  currentPage: number | null,
  selectedReq: Request | null
} = {
  creationStatus: Status.IDLE,
  data: <Request[]>[],
  selectedReq: null,
  status: Status.LOADING,
  error: null,
  filters: {},
  totalItems: 0,
  totalPages: 0,
  currentPage: 1
};

export const requestsReducer = (state = initialState, action: { type: string, payload: any }): any => {
  const { type, payload } = action;
  const view = LocalStorageService.getDefaultView();

  switch (type) {
    case CREATE_REQUEST_SUCCESS: {
      const { request } = payload;
      if (state.data.length === 25 &&
          view === View.LIST) state.data.pop();
      return {
        ...state,
        data: [request, ...state.data],
        creationStatus: Status.SUCCEESS
      };
    }
    case CREATE_REQUEST_IN_PROGRESS: {
      return {
        ...state,
        creationStatus: Status.LOADING,
      };
    }
    case CREATE_REQUEST_FAILURE: {
      return {
        ...state,
        creationStatus: Status.FAILED
      };
    }
    case EDIT_REQUEST: {
      const { request: editRequest } = payload;
      return {
        ...state,
        data: state.data.map((request) => {
          if (request.id === editRequest.id) {
            return editRequest;
          }
          return request;
        }),
      };
    }
    // Currently not used as we do shallow delete and not physical delete.
    case REMOVE_REQUEST: {
      const { requestRemoved } = payload;
      return {
        ...state,
        data: state.data.filter((request) => request.id !== requestRemoved.id),
      };
    }
    case LOAD_REQUESTS_SUCCESS: {
      const { requests } = payload;
      return {
        ...state,
        status: Status.SUCCEESS,
        data: requests,
      };
    }
    case LOAD_REQUESTS_IN_PROGRESS:
      return {
        ...state,
        status: Status.LOADING,
      };
    case LOAD_REQUESTS_FAILURE:
      return {
        ...state,
        status: Status.FAILED,
        error: 'Loading requests failed',
      };
    case LOAD_REQUEST_SUCCESS: {
      const { request } = payload;
      return {
        ...state,
        status: Status.SUCCEESS,
        selectedReq: request
      };
    }
    case LOAD_REQUEST_IN_PROGRESS:
      return {
        ...state,
        status: Status.LOADING,
      };
    case LOAD_REQUEST_FAILURE:
      return {
        ...state,
        status: Status.FAILED,
        error: 'Loading requests failed',
      };
    case UPDATE_TOTAL_ITEMS: {
      const { totalItems } = payload;
      return {
        ...state,
        totalItems
      };
    }
    case UPDATE_TOTAL_PAGES: {
      const { totalPages } = payload;
      return {
        ...state,
        totalPages
      };
    }
    case UPDATE_CURRENT_PAGE: {
      const { page } = payload;
      return {
        ...state,
        currentPage: page
      };
    }
    default:
      return state;
  }
};
