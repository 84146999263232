export enum Status {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEESS = 'success',
  FAILED = 'failed',
}

export interface InitialState<T> {
  data: T;
  status?: Status;
  error?: string | null;
  filters?: Record<string, unknown>;
}
