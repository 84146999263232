import './Avatar.scss';
import { useCallback, useEffect, useState } from 'react';
import { Tooltip } from 'react-tippy';

import classNames from 'classnames';

import ImageHandlerService from '../../services/helper/ImageHandlerService';
import { getInitials, stringToColour } from '../../shared/utility';

interface AvatarProps {
  user: any;
  style?: React.CSSProperties;
  disableTooltip?: boolean;
  className?: string;
  isClient?: boolean
}

const Avatar: React.FC<AvatarProps> = ({
  user,
  style,
  disableTooltip = true,
  className,
  isClient= false
}) => {
  const [preview, setPreview] = useState<{preview: string, name: string}>();
  const initials = isClient ? user?.name : `${user?.firstName} ${user?.lastName}`;
  const imageUri = useCallback(
    () => isClient ? user?.clientLogo?.filePath || null : user?.accountImage?.filePath || null,
    [isClient, user]
  );

  useEffect(() => {
    ImageHandlerService.setPreview(
      undefined,
      imageUri() || null,
      setPreview,
      'thum',
      true
    );
  }, [user, isClient, imageUri]);

  return (
    <div className={`avatar ${className}`} style={style}>
      <Tooltip
        animation="none"
        className={classNames('', {
          'tooltip-flex': !disableTooltip,
        })}
        delay={500}
        disabled={disableTooltip}
        duration={0}
        html={
          <div style={{ fontSize: '12px', paddingBottom: '5px' }}>
            {initials}
          </div>
        }
        position="top"
        title={`${user?.firstName} ${user?.lastName}`}
      >
        {user?.accountImage || user?.clientLogo ? (
          <span
            className="image-holder"
            style={{backgroundImage: `url(${preview?.preview})`}}
          />
        ) : (
          <div
            className="avatar__fallback"
            style={{
              backgroundColor: stringToColour(initials),
              fontSize:
                35 / (1.4 * Math.max(getInitials(initials).length, 2)),
            }}
          >
            {user ? <span>{getInitials(initials)}</span> : null}
          </div>
        )}
      </Tooltip>
    </div>
  );
};

export default Avatar;
