import Agency from '../../services/model/Agency';
import {
  LOAD_AGENCY_FAILURE,
  LOAD_AGENCY_IN_PROGRESS,
  LOAD_AGENCY_SUCCESS,
  UPDATE_AGENCY_FAILURE,
  UPDATE_AGENCY_SUCCESS
} from './actionTypes';

// Action creators.
export const loadAgencyInProgress = (): any => ({
  type: LOAD_AGENCY_IN_PROGRESS,
});
export const loadAgencySuccess = (agency: Agency): any => ({
  type: LOAD_AGENCY_SUCCESS,
  payload: { agency },
});
export const loadAgencyFailure = (): any => ({
  type: LOAD_AGENCY_FAILURE,
});
export const updateAgencySuccess = (agency: Agency): any => ({
  type: UPDATE_AGENCY_SUCCESS,
  payload: { agency },
});
export const updateAgencyFailure = (): any => ({
  type: UPDATE_AGENCY_FAILURE,
});
