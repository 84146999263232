import { motion } from 'framer-motion';
import './Spinner.scss';

const spinTransition = {
  loop: Infinity,
  duration: 1,
  ease: 'linear',
};

const Spinner: React.FC<{large?: boolean}> = ({large = false}) => (
  <div
    className={ large ? 'container-large' : 'container-small' }>
    <motion.span
      animate={{ rotate: 360 }}
      className={large ? 'circle circle--large' : 'circle circle--small'}
      transition={spinTransition}
    />
  </div>
);

export default Spinner;
