import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  persistReducer,
  persistStore,
} from "redux-persist";
import { encryptTransform } from 'redux-persist-transform-encrypt';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import rootReducer from './reducers';

const persistConfig = {
  timeout: 100,
  key: 'root',
  storage,
  transforms:[encryptTransform({
    secretKey: 'reduxPersistEncryptedSecretKey'
  })],
  stateReconciler: autoMergeLevel2,
  blacklist: ['auth', 'modal', 'mediaObjectReducer', 'requestCheckedListReducer'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer as any);

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch
export { persistor };
export default store;
