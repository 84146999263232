import { combineReducers } from 'redux';

import accessRequestPermissionsReducer from "./accessRequestPermissions";
import accountsReducer, { loadSpecificAccountsReducer, setAccountReducer } from './accounts';
import agencyReducer from './agency';
import auth from './auth';
import cannedResponsesReducer from './cannedResponses';
import { requestCheckedListReducer } from './checkedRequestList';
import clientsReducer from './clients';
import commentsReducer from './comments';
import formFieldsReducer from './formFields';
import {
  invitationInfoReducer as invitationInfo,
  invitationsReducer as invitations,
} from './invitations';
import labelsReducer from './labels';
import mediaObjects from './mediaObjects';
import { modalReducer as modal } from './modal';
import { notificationsReducer as notifications } from './notifications';
import privateNotesReducer from './privateNotes';
import progressReducer from './progress';
import requestPriorityReducer from "./requestPriority";
import { requestsReducer } from './requests';
import requestTypesReducer from './requestTypes';
import rolesReducer from './roles';
import statusFlagsReducer from './statusFlags';
import teamsReducer from './teams';
import timeLogsReducer from './timeLogs';

/* Create root reducer, containing all features of the application */
const rootReducer = combineReducers({
  auth,
  accountsReducer,
  agencyReducer,
  cannedResponsesReducer,
  clientsReducer,
  commentsReducer,
  invitations,
  invitationInfo,
  labelsReducer,
  modal,
  notifications,
  requestsReducer,
  requestCheckedListReducer,
  requestTypesReducer,
  rolesReducer,
  statusFlagsReducer,
  setAccountReducer,
  teamsReducer,
  timeLogsReducer,
  privateNotesReducer,
  formFieldsReducer,
  progressReducer,
  mediaObjects,
  accessRequestPermissionsReducer,
  requestPriorityReducer,
  loadSpecificAccountsReducer
});

export default rootReducer;
