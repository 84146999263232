import { lazy } from 'react';
import { useSelector } from 'react-redux';
import {Redirect, Route, Switch, useLocation} from 'react-router-dom';

import { AuthState } from '../../../store/reducers/auth';
import {getAuthData} from '../../../store/selectors/auth';
import ActivateAccount from "../../ActivateAccount/ActivateAccount";
import Login from '../../Login/Login';
import { ChildComponentProps } from '../RoutingAPI/Routes';

const FirstLogin = lazy(() => import('../../FirstLogin/FirstLogin'));
const ChangePassword = lazy(
  () => import('../../ChangePassword/ChangePassword')
);
const ForgotPassword = lazy(
  () => import('../../ForgotPassword/ForgotPassword')
);
const MainContainer = lazy(() => import('../MainContainer/MainContainer'));
const TermsAndPrivacy = lazy (() => import ('../../TermsAndPrivacy/TermsAndPrivacy'));

const Content: React.FC<ChildComponentProps> = ({
  items,
}: ChildComponentProps) => {
  const authData: AuthState = useSelector((state) => getAuthData(state));
  let content;
  const location = useLocation();

  const isAuthenticated = authData.authenticated;
  if (isAuthenticated) {
    content = <MainContainer items={items} />;
  } else {
    content = (
      <Switch>
        <Route component={Login} path="/auth" />
        <Route component={ForgotPassword} path="/reset-password" />
        <Route component={TermsAndPrivacy} path="/terms"/>
        <Route
          component={ChangePassword}
          path="/accounts/change-password"
        />
        <Route
          component={ActivateAccount}
          path="/accounts/activate-account"
        />
        <Route
          component={FirstLogin}
          path="/accept-invitation"
        />
        <Redirect from={location.pathname}  to={{
          pathname: '/auth',
          state: { from: location }
        }}/>
      </Switch>
    );
  }

  return <>{content}</>;
};

export default Content;
