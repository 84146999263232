import { Roles } from '../../shared/utility';
import {
  CREATE_ROLES_SUCCESS,
  LOAD_ROLES_FAILURE,
  LOAD_ROLES_SUCCESS,
} from './actionTypes';

// Action creators.
export const createRolesSucces = (roles: Roles[]): any => ({
  type: CREATE_ROLES_SUCCESS,
  payload: { roles },
});
export const loadRolesSuccess = (roles: Roles[]): any => ({
  type: LOAD_ROLES_SUCCESS,
  payload: { roles },
});
export const loadRolesFailure = (): any => ({
  type: LOAD_ROLES_FAILURE,
});
