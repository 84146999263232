import Accounts from './api/Account';
import { AccountChangePasswordRequest } from './request/AccountChangePasswordRequest';
import { AccountResetPasswordRequest } from './request/AccountResetPasswordRequest';

const AccountService = {

  resetPassword: async (data: AccountResetPasswordRequest): Promise<any> => {
    await Accounts.resetPassword(data);
  },

  changePassword: async (
    userId: number,
    data: AccountChangePasswordRequest
  ) : Promise<any> => {
    await Accounts.changePassword(userId, data);
  },
};

export default AccountService;
