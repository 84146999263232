import { StrictMode } from 'react';
import { CookiesProvider } from 'react-cookie';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import {GoogleOAuthProvider} from "@react-oauth/google";
import { /*  webpackPreload: true */ SnackbarProvider } from 'notistack';
import './index.scss';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import store from './store/store';

const persistor = persistStore(store);

const clientId = '578527087236-qa2r3c4fd6clecdn6otcs2pcai48htab.apps.googleusercontent.com';

render (
  <SnackbarProvider
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    autoHideDuration={3000}
    dense
    maxSnack={3}
  >
    <GoogleOAuthProvider clientId={clientId}>
    <StrictMode>
      <CookiesProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter basename="/app">
                <App />
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </CookiesProvider>
    </StrictMode>
    </GoogleOAuthProvider>
  </SnackbarProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
