import { Action } from '../../services/model/Action';
import Agency from '../../services/model/Agency';
import {
  LOAD_AGENCY_FAILURE,
  LOAD_AGENCY_IN_PROGRESS,
  LOAD_AGENCY_SUCCESS,
  UPDATE_AGENCY_FAILURE,
  UPDATE_AGENCY_IN_PROGRESS,
  UPDATE_AGENCY_SUCCESS
} from '../actions/actionTypes';
import { InitialState, Status } from '../utillity';

const initialState: InitialState<Agency[]> = {
  data: <Agency[]>[],
  status: Status.IDLE,
  error: null,
};

const agencyReducer = (state = initialState, action: Action): any => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_AGENCY_SUCCESS: {
      const { agency } = payload;
      return {
        ...state,
        status: Status.SUCCEESS,
        data: agency,
      };
    }
    case LOAD_AGENCY_IN_PROGRESS:
      return {
        ...state,
        status: Status.LOADING,
      };
    case LOAD_AGENCY_FAILURE: {
      return {
        ...state,
        status: Status.FAILED,
      };
    }
    case UPDATE_AGENCY_SUCCESS: {
      const { agency } = payload;
      return {
        ...state,
        status: Status.SUCCEESS,
        data: agency,
      };
    }
    case UPDATE_AGENCY_IN_PROGRESS:
      return {
        ...state,
        status: Status.LOADING,
      };
    case UPDATE_AGENCY_FAILURE: {
      return {
        ...state,
        status: Status.FAILED,
      };
    }
    default:
      return state;
  }
};

export default agencyReducer;
