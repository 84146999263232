import { Account } from '../../services/model/Account';
import { Action } from '../../services/model/Action';
import {
  CREATE_ACCOUNT,
  EDIT_ACCOUNT,
  LOAD_ACCOUNTS_FAILURE,
  LOAD_ACCOUNTS_IN_PROGRESS,
  LOAD_ACCOUNTS_SUCCESS, LOAD_SPECIFIC_ACCOUNTS_SUCCESS,
  REMOVE_ACCOUNT,
  RESET_ACCOUNTS,
  SET_ACCOUNT_FAILURE,
  SET_ACCOUNT_SUCCESS,
} from '../actions/actionTypes';
import { InitialState, Status } from '../utillity';

const initialState: InitialState<Account[]> = {
  data: <Account[]>[],
  status: Status.IDLE,
  error: null,
};

const accountsReducer = (state = initialState, action: Action): any => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_ACCOUNT: {
      const { account } = payload;
      const loggedAccountIndex = state.data.findIndex(acc => account.id === acc.id);
      let tmpArr = [...state.data];

      if (loggedAccountIndex !== -1) {
        tmpArr[loggedAccountIndex] = account;
      } else {
        tmpArr = [...tmpArr.concat(account)];
      }

      return {
        ...state,
        data: tmpArr
      };
    }
    case EDIT_ACCOUNT: {
      const { account: editAccount } = payload;
      return {
        ...state,
        data: state.data.map((account) => {
          if (account.id === editAccount.id) {
            return editAccount;
          }
          return account;
        }),
      };
    }
    // Currently not used as we do shallow delete and not physical delete.
    case REMOVE_ACCOUNT: {
      const { accountRemoved } = payload;
      return {
        ...state,
        data: state.data.filter((account) => account.id !== accountRemoved.id),
      };
    }
    case LOAD_ACCOUNTS_SUCCESS: {
      const { accounts } = payload;
      return {
        ...state,
        status: Status.SUCCEESS,
        data: accounts,
      };
    }
    case LOAD_ACCOUNTS_IN_PROGRESS:
      return {
        ...state,
        status: Status.LOADING,
      };
    case LOAD_ACCOUNTS_FAILURE: {
      return {
        ...state,
        status: Status.FAILED,
      };
    }
    case RESET_ACCOUNTS :{
      return {
        ...state,
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export const loadSpecificAccountsReducer = (state = initialState, action: Action): any => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_SPECIFIC_ACCOUNTS_SUCCESS: {
      const {accounts} = payload;
      return {
        ...state,
        status: Status.SUCCEESS,
        data: accounts,
      };
    }
    default:
      return state;
  }
};

export const setAccountReducer = (state = initialState, action: Action): any => {
  const { type, payload } = action;
  switch (type) {
    case SET_ACCOUNT_SUCCESS: {
      const { accounts } = payload;
      return {
        ...state,
        status: Status.SUCCEESS,
        data: accounts,
      };
    }
    case SET_ACCOUNT_FAILURE: {
      return {
        ...state,
        status: Status.FAILED,
      };
    }
    default:
      return state;
  }
};

export default accountsReducer;
