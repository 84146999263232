/* eslint-disable array-callback-return */
import jwt_decode from 'jwt-decode';

import Accounts from '../../services/api/Account';
import {NotificationService, Variant,} from '../../services/NotificationService';
import {authFail, authStart } from '../actions/auth';
import {enqueueSnackbar} from '../actions/notifications';
import LocalStorageService from '../LocalStorageServices';
import {AppDispatch} from '../store';
import {setUser} from "./shared";

const generateNotification = (errorMessage: string, error: any)  => async (
  dispatch: AppDispatch
): Promise<any>  =>{
  const notification = NotificationService.create(
    `${errorMessage}`,
    Variant.ERROR
  );
  dispatch(enqueueSnackbar(notification));
  dispatch(authFail(error));
};

export const auth = (username: string, password: string, isGoogleLogin?: boolean) => async (
  dispatch: AppDispatch
): Promise<any> => {
  try {
    dispatch(authStart());
    const res = isGoogleLogin ?
      await Accounts.googleLogin(username) :
      await Accounts.login({ username, password, additionalCondition: "noRedirect" });
    const accessTokenEncrypted = res.data.token;
    const accessToken: any = jwt_decode(accessTokenEncrypted);
    const refreshTokenEncrypted = res.data.refresh_token;
    LocalStorageService.setAccessToken(accessTokenEncrypted);
    LocalStorageService.setRefreshToken(refreshTokenEncrypted);

    const account = await Accounts.singleByEmail(accessToken.username);
    dispatch<any>(setUser(accessTokenEncrypted, account?.data[0]));
    return res;
  } catch (error: any) {
    let errorMessage ;

      switch (error.response?.status) {
        case 401:
          errorMessage = 'Wrong credentials';
          break;
        case 403:
          errorMessage = 'Access forbidden';
          window.open(error.response?.data.detail);
          break;
        default:
          errorMessage = 'Wrong credentials';
      }

    dispatch<any>(generateNotification(errorMessage, error));
    return error;
  }
};

export const signout = () => async (): Promise<void> => {
  LocalStorageService.clearToken();
};
