import { RequestType } from '../../services/model/Request';
import {
  CREATE_REQUEST_TYPE,
  EDIT_REQUEST_TYPE,
  LOAD_REQUEST_TYPES_FAILURE,
  LOAD_REQUEST_TYPES_SUCCESS,
  REMOVE_REQUEST_TYPE,
} from '../actions/actionTypes';
import { InitialState, Status } from '../utillity';

const initialState: InitialState<RequestType[]> = {
  data: <RequestType[]>[],
  error: null,
  status: Status.IDLE,
};

const requestTypesReducer = (state = initialState, action: { type: string, payload: any }): any => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_REQUEST_TYPE: {
      const { requestType } = payload;
      return {
        ...state,
        data: state.data.concat(requestType),
      };
    }
    case EDIT_REQUEST_TYPE: {
      const { requestType: editRequestType } = payload;
      return {
        ...state,
        data: state.data.map((requestType) => {
          if (requestType.id === editRequestType.id) {
            return editRequestType;
          }
          return requestType;
        }),
      };
    }
    // Shallow delete and not physical delete.
    case REMOVE_REQUEST_TYPE: {
      const { requestTypeId } = payload;
      return {
        ...state,
        data: state.data.map((requestType) => {
          if (requestType.id === requestTypeId) {
            const requestTypeRemoved = requestType;
            requestTypeRemoved.status = false;
            return requestTypeRemoved;
          }
          return requestType;
        }),
      };
    }
    case LOAD_REQUEST_TYPES_SUCCESS: {
      const { requestTypes } = payload;
      return {
        ...state,
        data: requestTypes,
        status: Status.SUCCEESS,
      };
    }
    case LOAD_REQUEST_TYPES_FAILURE: {
      return {
        ...state,
        error: 'Load request types failed.',
        status: Status.FAILED,
      };
    }
    default:
      return state;
  }
};

export default requestTypesReducer;
