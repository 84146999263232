/* eslint-disable jsx-a11y/label-has-associated-control */
import {useEffect} from "react";
import { useForm } from 'react-hook-form';
import Iframe from 'react-iframe';
import { useDispatch, useSelector } from 'react-redux';
import './Login.scss';
import { useHistory, useLocation } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';

import loginImg from '../../assets/images/log-in.png';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import Spinner from '../../components/spinner/Spinner';
import LoginSchema from '../../services/validation/LoginSchema';
import { ENTER_KEY } from "../../shared/utility";
import { getAuthStatus } from '../../store/selectors/auth';
import { AppDispatch, persistor } from '../../store/store';
import { auth } from '../../store/thunks/auth';
import { Status } from '../../store/utillity';

interface ILoginForm {
  username: string;
  password: string;
}

const Login: React.FC = () => {
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm<ILoginForm>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: yupResolver(LoginSchema),
  });

  const {REACT_APP_GOOGLE_LOGIN_URL} = process.env;
  const subdomain = window.location.host.split('.')[0];
  const location = useLocation();

  const onSubmit = (data: any) => {
    persistor.purge().then(() => {
      dispatch<any>(auth(data.username, data.password, data?.isGoogleLogin))
        .then(() => {
          if (location.state) {
            // @ts-ignore
            history.push(`${location.state.from.pathname}${location.state.from?.search}`);
          }
        });
    });
  };

  const storageChange = (e: any) => {
    if (!e.data?.username) {
      return;
    }

    onSubmit({
      username: e.data.username,
      password: '',
      isGoogleLogin: true
    });
  };

  const listener = (e: any) => {
    if (e.origin === REACT_APP_GOOGLE_LOGIN_URL) storageChange(e);
  };

  useEffect(() => {
    window.addEventListener('message', listener, false);

    return () => {
      window.removeEventListener('message', listener);
    };
  });

  const authStatus = useSelector((state: any) :any => getAuthStatus(state));

  const sendEmail = () => {
    history.push('reset-password', "");
  };

  return (
    <div className="region--content region--content--2-col region--content--page-auth">
      <div className="page--auth page--form">
        <div className="content content--left">
          <div className="logo">
            <a href="temp">
              <Logo />
            </a>
          </div>
          <div className="form-header">
            <h1 className="page-title">Log In</h1>
            <Iframe allowFullScreen
                    frameBorder={0}
                    height='40px'
                    loading='lazy'
                    overflow='hidden'
                    scrolling='no'
                    url={`${REACT_APP_GOOGLE_LOGIN_URL}?action=login&value=${subdomain}`}
                    width='250px'
            />
          </div>
          <form
            className="form form--login"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="form__item form__item--email add-input-margin">
              <label htmlFor="username">Email Address</label>
              <input
                className="form--email"
                name="username"
                ref={register}
                type="email"
              />
              {errors.username && (
                <p className="form-error">{errors.username.message}</p>
              )}
            </div>
            <div className="form__item form__item--password">
              <label htmlFor="password">Password</label>
              <input
                className="form--password"
                name="password"
                ref={register({ required: true })}
                type="password"
              />
              {errors.password && (
                <p className="form-error">{errors.password.message}</p>
              )}
              <div className="form__item-footer">
                <div className="form__item form__item--checkbox">
                  <input
                    className="form--checkbox"
                    id="remember_me"
                    type="checkbox"
                  />
                  <label htmlFor="remember_me">Remember me</label>
                </div>
                <span
                  className="link link--dark link--medium link--pointer"
                  onClick={sendEmail}
                  onKeyUp={(e: any) => {
                    if(e.key === ENTER_KEY)
                      sendEmail();
                  }}
                  role="button"
                  tabIndex={0}
                >
                  Forgot password?
                </span>
              </div>
            </div>
            <div className="form__actions">
              <button
                className="form--submit button button--big button--red login__button"
                type="submit"
              >
                {authStatus === Status.LOADING ? <Spinner /> : 'Log In'}
              </button>
            </div>
          </form>
        </div>
        <div className="content content--right hide-mobile">
          <img alt="Login" loading='lazy' src={loginImg} />
        </div>
      </div>
    </div>
  );
};

export default Login;
