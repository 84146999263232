import {RequestPriority} from "../../services/model/RequestType";
import {
  LOAD_REQUEST_PRIORITY_FAILURE,
  LOAD_REQUEST_PRIORITY_SUCCESS,
} from "../actions/actionTypes";
import {InitialState, Status} from "../utillity";

const initialState: InitialState<RequestPriority[]> = {
  data: <RequestPriority[]>[],
  error: null,
  status: Status.IDLE,
};

const requestPriorityReducer = (state = initialState, action: { type: string, payload: any }): any => {
  const {type, payload} = action;

  switch (type) {

    case LOAD_REQUEST_PRIORITY_SUCCESS: {
      const { requestPriority } = payload;
      return {
        ...state,
        data: requestPriority,
        status: Status.SUCCEESS,
      };
    }
    case LOAD_REQUEST_PRIORITY_FAILURE: {
      return {
        ...state,
        error: 'Load request priority failed.',
        status: Status.FAILED,
      };
    }
    default:
      return state;
  }
};

export default requestPriorityReducer;
