import './Header.scss';

import { useSelector } from 'react-redux';

import { isRequestDetailModalOpen } from "../../../store/selectors/modal";
import { getTabIndexValue } from "../../Request/utility";
import { ChildComponentProps } from '../RoutingAPI/Routes';
import Logo from './Logo';
import Nav from './Nav';
import ToggleIcon from './ToggleIcon';
import UserAvatar from './UserAvatar';

const Header: React.FC<ChildComponentProps> = ({
  items,
}: ChildComponentProps) => {

  const isRequestOpen = useSelector((state) => isRequestDetailModalOpen(state));

  return (
    <header className="app--header region--header">
      <div className="region__inner header--primary">
        <Logo
          tabIndexValue={getTabIndexValue(isRequestOpen)}
        />
        <ToggleIcon />
        <Nav
          isRequestOpen={isRequestOpen}
          items={items}
        />
        <UserAvatar
          isRequestOpen={isRequestOpen}
        />
      </div>
    </header>
  );
};

export default Header;
