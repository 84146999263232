/* eslint-disable import/prefer-default-export */
import { Request } from '../../services/model/Request';
import { CLEAR_SELECTED_REQUESTS, SELECT_ALL_REQUEST, SELECT_REQUEST, UNSELECT_REQUEST } from '../actions/actionTypes';
import { InitialState } from '../utillity';

const initialListState: InitialState<Request[]> = {
  data: <Request[]>[],
};

export const requestCheckedListReducer: any = (
  state = initialListState,
  action: { type: string, payload: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case SELECT_REQUEST: {
      const { request } = payload;
      return {
        ...state,
        data: state.data.concat(request),
      };
    }
    case SELECT_ALL_REQUEST: {
      const { requests } = payload;
      return {
        ...state,
        data: requests,
      };
    }
    case UNSELECT_REQUEST: {
      const { requestRemoved } = payload;
      return {
        ...state,
        data: state.data.filter(
          (request) => requestRemoved && request.id !== requestRemoved.id
        ),
      };
    }
    case CLEAR_SELECTED_REQUESTS:
      return initialListState;
    default:
      return state;
  }
};
