import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SnackbarKey, useSnackbar } from 'notistack';

import { Notification } from '../../services/model/Notification';
import { removeSnackbar } from '../../store/actions/notifications';
import getNotifications from '../../store/selectors/notifications';

let displayed: any[] = [];

const Snackbar = (): any => {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => getNotifications(state));
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: string) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  useEffect(() => {
    notifications.forEach((notification: Notification) => {
      if (notification.dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(notification.key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(notification.key)) return;
      const { key } = notification;
      // display snackbar using notistack
      enqueueSnackbar(notification.message, {
        key,
        ...notification.options,
        onClose: (event, reason, myKey) => {
          if (notification.options.onClose) {
            notification.options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey: any) => {
          dispatch(removeSnackbar(myKey));
          removeDisplayed(myKey);
        },
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(notification.key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
};

export default Snackbar;
