import { SnackbarKey } from 'notistack';

import { Notification } from '../../services/model/Notification';
import {
  CLOSE_SNACKBAR,
  ENQUEUE_SNACKBAR,
  REMOVE_SNACKBAR,
} from './actionTypes';

export const enqueueSnackbar = (notification: Notification): any => {
  const { key } = notification;

  return {
    type: ENQUEUE_SNACKBAR,
    payload: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
    },
  };
};

export const closeSnackbar = (key: SnackbarKey): any => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});

export const removeSnackbar = (key: SnackbarKey): any => ({
  type: REMOVE_SNACKBAR,
  payload: {
    key: key || new Date().getTime() + Math.random(),
  },
});
