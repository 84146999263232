export const isModalOpen = (state: Record<string, any>): boolean => state.modal.isOpen;
export const isClientModalOpen = (state: Record<string, any>): boolean =>
  state.modal.isClientOpen;
export const isInvitationModalOpen = (state: Record<string, any>): boolean =>
  state.modal.isInvitationOpen;
export const isNavModalOpen = (state: Record<string, any>): boolean => state.modal.isNavOpen;
export const isRequestDetailModalOpen = (state: Record<string, any>): boolean =>
  state.modal.isRequestDetailOpen;
export const isTimeLoggingModalOpen = (state: Record<string, any>): boolean =>
  state.modal.isTimeLoggingOpen;
export const isDeleteRequestTypeModalOpen = (state: Record<string, any>): boolean =>
  state.modal.isDeleteRequestTypeOpen;
export const isSubmitRequestModalOpen = (state: Record<string, any>): boolean =>
  state.modal.isSubmitRequestOpen;
export const isRequestFormPreviewModalOpen = (state: Record<string, any>): boolean =>
  state.modal.isRequestFormPreviewOpen;
export const isCreateLabelModalOpen = (state: Record<string, any>): boolean =>
  state.modal.isCreateLabelOpen;
export const isAddLabelModalOpen = (state: Record<string, any>): boolean =>
  state.modal.isAddLabelOpen;
export const isSubmitRequestConfirmationModalOpen = (state: Record<string, any>): boolean =>
  state.modal.isSubmitRequestConfirmationOpen;
export const isImagePreviewModalOpen = (state: Record<string, any>): boolean =>
  state.modal.isImagePreviewOpen;
export const isToastMessageOpen = (state: Record<string, any>): boolean =>
  state.modal.isToastMessageOpen;
