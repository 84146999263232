import { AuthState } from '../reducers/auth';
import {
  AUTH_FAIL,
  AUTH_START,
  AUTH_SUCCESS,
} from './actionTypes';

// Action creators
export const authStart = (): any => ({
  type: AUTH_START,
});
export const authSuccess = (auth: AuthState): any => ({
  type: AUTH_SUCCESS,
  payload: auth,
});
export const authFail = (error: string): any => ({
  type: AUTH_FAIL,
  error,
});
