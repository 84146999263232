import { Action } from '../../services/model/Action';
import {
  CREATE_INVITATION,
  CREATE_INVITATION_FAILURE,
  CREATE_INVITATION_IN_PROGRESS,
  LOAD_INVITATION_INFO_FAILURE,
  LOAD_INVITATION_INFO_IN_PROGRESS,
  LOAD_INVITATION_INFO_SUCCESS,
  LOAD_INVITATIONS_FAILURE,
  LOAD_INVITATIONS_IN_PROGRESS,
  LOAD_INVITATIONS_SUCCESS,
  REVOKE_INVITATION_FAILURE,
  REVOKE_INVITATION_IN_PROGRESS,
  REVOKE_INVITATION_SUCCESS,
} from '../actions/actionTypes';
import { InitialState, Status } from '../utillity';

export interface InvitationsState {
  id?: string;
  email: string;
  name: string;
  status: boolean;
  error: any;
}

export interface ActionInProgress {
  inProgress: boolean;
  id: number;
}

export interface InvitationInfoState {
  full_name: string;
  email: string;
  client: number;
  agency: number;
  invited_by: number;
  team: number;
  invited_by_account: string;
}

export interface InvitationInitialState<T> extends InitialState<T> {
  revokeInProgress: ActionInProgress;
  resendInProgress: ActionInProgress;
}
const initialState: InvitationInitialState<InvitationsState[]> = {
  data: <InvitationsState[]>[],
  revokeInProgress: <ActionInProgress>{},
  resendInProgress: <ActionInProgress>{},
  status: Status.IDLE,
  error: null,
};

export const invitationsReducer = (state = initialState, action: Action): any => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_INVITATION_IN_PROGRESS: {
      return {
        ...state,
        resendInProgress: {
          inProgress: true,
          id: payload,
        },
      };
    }
    case CREATE_INVITATION: {
      const { invitation } = payload;
      return {
        ...state,
        resendInProgress: {
          inProgress: false,
          id: payload.id,
        },
        data: state.data.concat(invitation),
      };
    }
    case CREATE_INVITATION_FAILURE: {
      return {
        ...state,
        resendInProgress: {
          inProgress: false,
          id: payload,
        },
      };
    }
    case REVOKE_INVITATION_IN_PROGRESS: {
      return {
        ...state,
        revokeInProgress: {
          inProgress: true,
          id: payload,
        },
      };
    }
    case REVOKE_INVITATION_SUCCESS: {
      return {
        ...state,
        revokeInProgress: {
          inProgress: false,
        },
      };
    }
    case REVOKE_INVITATION_FAILURE: {
      return {
        ...state,
        revokeInProgress: {
          inProgress: false,
          id: payload,
        },
      };
    }
    case LOAD_INVITATIONS_SUCCESS: {
      const { invitations } = payload;
      return {
        ...state,
        status: Status.SUCCEESS,
        data: invitations,
      };
    }
    case LOAD_INVITATIONS_IN_PROGRESS:
      return {
        ...state,
        status: Status.LOADING,
      };
    case LOAD_INVITATIONS_FAILURE: {
      return {
        ...state,
        status: Status.FAILED,
      };
    }
    default:
      return state;
  }
};

const initialInfoState = {
  isLoading: false,
  data: <InvitationInfoState[]>[],
};
export const invitationInfoReducer = (
  state = initialInfoState,
  action: Action
): any => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_INVITATION_INFO_SUCCESS: {
      const { invitation } = payload;

      return {
        ...state,
        isLoading: false,
        data: invitation,
      };
    }
    case LOAD_INVITATION_INFO_IN_PROGRESS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case LOAD_INVITATION_INFO_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
