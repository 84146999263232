import { get } from '../../config/api';

// API Endpoints
const TEAMS_URL = '/teams';

const Teams = {
  index: (): any => get(TEAMS_URL),
  getPaginationData: (page: number): any =>
    get(TEAMS_URL, { params: { hydra: true, page, status: 1 } }),
  single: (id: number): any =>
    get(`${TEAMS_URL}/${id}`, { params: { status: 1 } }),
};

export default Teams;
