import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import './Nav.scss';
import classNames from 'classnames';

import { isNavModalOpen } from '../../../store/selectors/modal';
import { signout } from '../../../store/thunks/auth';
import { ChildComponentProps } from '../RoutingAPI/Routes';

const Nav: React.FC<ChildComponentProps> = ({ items, isRequestOpen }) => {
  const isNavOpen = useSelector((state) => isNavModalOpen(state));
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(signout());
  };
  const navItems = items.map((item) => (
    <li className="nav__item" key={item.title}>
      <NavLink
        activeClassName="is-active"
        className="nav__link"
        exact={item.isHomePage}
        tabIndex={!isRequestOpen ? 0 : -1}
        to={item.path}
      >
        {item.title}
      </NavLink>
      {
        item.childrenRoutes !== null ?
        <>
           <ul className="hide--desktop header--sidebar padding--top">
             {
              item.childrenRoutes?.map(child =>
                <li className="padding--bottom" key={Math.random()}>
                  <NavLink
                    className="nav__link"
                    exact={child.isHomePage}
                    key={child.title}
                    to={child.path}
                  >
                    {child.title}
                  </NavLink>
                </li>
              )}
           </ul>
        </>
        :
        item.title
        }
    </li>
  ));

  return (
    <nav className={classNames('nav nav--main', { open: isNavOpen })}>
      <ul className="nav__list">
        {navItems}
        <li className="nav__item nav__item--sign-out border-top border-full">
          <span
            className="nav__link"
            onClick={logout}
            onKeyUp={logout}
            role="button"
            tabIndex={0}
          >
            Sign Out
          </span>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
