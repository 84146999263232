import axios from 'axios';
import jwt_decode from 'jwt-decode';

import packageJson from '../../../package.json';
import LocalStorageService from '../../store/LocalStorageServices';
import ApiRolesBlacklist from './api-roles-blacklist';

const apiClient = axios.create({
  baseURL: '/api/v1',
});
const { version } = packageJson;
const checkContentType = (conf: any) =>
  conf.params?.hydra ? 'application/ld+json' : 'application/json';
const ERROR_MESSAGE = 'version error';

const { get, post, put, patch, delete: destroy } = apiClient;
export { get, post, put, patch, destroy };

const refreshJwt = async () => {
  const newAuthRes = await post("/token/refresh", {
    refresh_token: LocalStorageService.getRefreshToken(),
  });
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const {token, refresh_token} = newAuthRes.data;

  // saveUserToken(newAuthRes.access_token);  <-- save new token
  LocalStorageService.setAccessToken(token);

  // Save new refresh token
  LocalStorageService.setRefreshToken(refresh_token);
};

const checkBlackListUrl = (roles: string[], apiUrl: string): boolean => {
  const a = roles.map((role: string) => (ApiRolesBlacklist as {[key: string]: string[]})[role]);
  // eslint-disable-next-line prefer-spread
  const merged = [].concat.apply([], a as any);

  return merged.some((url: string) => apiUrl.includes(url));
};

apiClient.interceptors.request.use(
  (config: any) => {
    const conf = config;
    const accessToken = LocalStorageService.getAccessToken();
    let decodedToken!: any;

    if (accessToken) {
      conf.headers.Authorization = `Bearer ${accessToken}`;
      decodedToken = jwt_decode(accessToken);
    }

    conf.headers = {
      ...conf.headers,
      'Access-Control-Allow-Origin': '*',
      'version': version,
      'Content-Type':
        config.method === 'patch'
          ? 'application/merge-patch+json'
          : checkContentType(conf),

      Accept: checkContentType(conf),
    };

    if (decodedToken && checkBlackListUrl(decodedToken.roles, conf.url)) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw new axios.Cancel('Token is not available. Do login, please.');
    }

    return conf;
  },
  (error: any) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  // Return a successful response back to the calling service.
  (response: any) => response,
  async (error: any) => {

    if (!error.response) {
      LocalStorageService.clearToken();
      if (!error.config.url.includes('login_check'))
        window.location.href = 'auth';
    }

    if (error.response?.data.detail === ERROR_MESSAGE) {
      LocalStorageService.clearToken();
      // eslint-disable-next-line no-alert
      window.alert('The versions do not match, please log in again.');
      window.location.reload();
    }

    if (error.response?.status === 410) {
      LocalStorageService.clearToken();

      if (!error.config.url.includes('login_check'))
       window.location.href = 'auth';
    }

    // Return any error which is not due to authentication back to the calling service
    if (error.response?.status !== 401) {
      return Promise.reject(error);
    }

    const originalRequest = error.config;

    if (originalRequest.url.includes('/token/refresh')) {
      LocalStorageService.clearToken();
      window.location.href = 'auth';
    }

    switch (originalRequest.url)
    {
      case '/accounts/google-login' :
      case '/login_check?noRedirect': {
        break;
      }
      default: {
        if (error.response && error.response.status === 401) {
          await refreshJwt();
          return apiClient(originalRequest);
        }
      }
    }

    return Promise.reject(error);
  }
);
