import AccountService from "../../services/AccountService";
import { NotificationService, Variant } from "../../services/NotificationService";
import { AccountChangePasswordRequest } from "../../services/request/AccountChangePasswordRequest";
import { enqueueSnackbar } from "../actions/notifications";
import { AppDispatch } from "../store";

const changePassword = (userId: number,data: AccountChangePasswordRequest) => async (
  dispatch: AppDispatch
): Promise<void> => {
  try {
    await AccountService.changePassword(userId, data);
    const notification = NotificationService.create(
    'Password is successfully changed',
    Variant.SUCCESS
    );
    dispatch(enqueueSnackbar(notification));
} catch (error) {
    const notification = NotificationService.create(
    `Change password failed with error: ${error}!`,
    Variant.ERROR
    );
    dispatch(enqueueSnackbar(notification));
  }
};
  
export default changePassword;
  