import { ReactComponent as LogoImage } from '../../../assets/images/logo.svg';

import './Logo.scss';

interface HeaderLogoProps {
  tabIndexValue?: number;
}

const Logo: React.FC<HeaderLogoProps> = ({
 tabIndexValue
}) => (
  <div className="logo">
    <a
      className="header-logo"
      href="request"
      tabIndex={tabIndexValue}
    >
      <LogoImage />
    </a>
  </div>
);

export default Logo;
