import { Action } from '../../services/model/Action';
import CannedResponse from '../../services/model/CannedResponse';
import {
  CREATE_CANNED_RESPONSE,
  EDIT_CANNED_RESPONSE,
  LOAD_CANNED_RESPONSES_FAILURE,
  LOAD_CANNED_RESPONSES_IN_PROGRESS,
  LOAD_CANNED_RESPONSES_SUCCESS,
  REMOVE_CANNED_RESPONSE,
} from '../actions/actionTypes';
import { InitialState, Status } from '../utillity';

const initialState: InitialState<CannedResponse[]> = {
  data: <CannedResponse[]>[],
  status: Status.IDLE,
  error: null,
};

const cannedResponsesReducer = (state = initialState, action: Action): any => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_CANNED_RESPONSE: {
      const { cannedResponse } = payload;
      return {
        ...state,
        data: state.data.concat(cannedResponse),
      };
    }
    case EDIT_CANNED_RESPONSE: {
      const { cannedResponse: editCannedResponse } = payload;
      return {
        ...state,
        data: state.data.map((cannedResponse) => {
          if (cannedResponse.id === editCannedResponse.id) {
            return editCannedResponse;
          }
          return cannedResponse;
        }),
      };
    }
    case REMOVE_CANNED_RESPONSE: {
      const { cannedResponseId } = payload;
      return {
        ...state,
        data: state.data.filter(
          (cannedResponse) => cannedResponse.id !== cannedResponseId
        ),
      };
    }
    case LOAD_CANNED_RESPONSES_SUCCESS: {
      const { cannedResponses } = payload;
      return {
        ...state,
        status: Status.SUCCEESS,
        data: cannedResponses,
      };
    }
    case LOAD_CANNED_RESPONSES_IN_PROGRESS:
      return {
        ...state,
        status: Status.LOADING,
      };
    case LOAD_CANNED_RESPONSES_FAILURE: {
      return {
        ...state,
        status: Status.FAILED,
      };
    }
    default:
      return state;
  }
};

export default cannedResponsesReducer;
