import { Account } from '../../services/model/Account';
import {
  CREATE_ACCOUNT,
  EDIT_ACCOUNT,
  LOAD_ACCOUNTS_FAILURE,
  LOAD_ACCOUNTS_IN_PROGRESS,
  LOAD_ACCOUNTS_SUCCESS, LOAD_SPECIFIC_ACCOUNTS_SUCCESS,
  REMOVE_ACCOUNT,
  RESET_ACCOUNTS,
  SET_ACCOUNT_FAILURE,
  SET_ACCOUNT_SUCCESS,
} from './actionTypes';

// Action creators.
export const createAccount = (account: Account): any => ({
  type: CREATE_ACCOUNT,
  payload: { account },
});
export const editAccount = (account: Account): any => ({
  type: EDIT_ACCOUNT,
  payload: { account },
});
export const removeAccount = (account: Account): any => ({
  type: REMOVE_ACCOUNT,
  payload: { account },
});
export const setAccountSuccess = (account: Account): any => ({
  type: SET_ACCOUNT_SUCCESS,
  payload: {account},
});
export const setAccountFailure = (): any => ({
  type: SET_ACCOUNT_FAILURE,
});
export const loadAccountsInProgress = (): any => ({
  type: LOAD_ACCOUNTS_IN_PROGRESS,
});
export const loadAccountsSuccess = (accounts: Account[]): any => ({
  type: LOAD_ACCOUNTS_SUCCESS,
  payload: { accounts },
});
export const loadSpecificAccountsSuccess = (accounts: Account[]): any => ({
  type: LOAD_SPECIFIC_ACCOUNTS_SUCCESS,
  payload: { accounts },
});
export const loadAccountsFailure = (): any => ({
  type: LOAD_ACCOUNTS_FAILURE,
});
export const resetAccounts = (): any => ({
  type: RESET_ACCOUNTS,
});
