import { useDispatch } from 'react-redux';

import { toggleNav } from '../../../store/thunks/modal';

import './Header.scss';

const ToggleIcon: React.FC = () => {
  const dispatch = useDispatch();
  return (
    <span
      className="trigger trigger--menu-open hide-desktop"
      onClick={() => dispatch(toggleNav(true))}
      onKeyUp={() => dispatch(toggleNav(true))}
      role="button"
      tabIndex={0}
    >
      Menu open
    </span>
  );
};

export default ToggleIcon;
