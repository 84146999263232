import { Action } from '../../services/model/Action';
import Comment from '../../services/model/Comment';
import {
  CREATE_COMMENT,
  EDIT_COMMENT,
  LOAD_COMMENTS_FAILURE,
  LOAD_COMMENTS_IN_PROGRESS,
  LOAD_COMMENTS_SUCCESS,
  REMOVE_COMMENT,
} from '../actions/actionTypes';
import { InitialState, Status } from '../utillity';

const initialState: InitialState<Comment[]> = {
  data: <Comment[]>[],
  status: Status.IDLE,
  error: null,
};

const commentsReducer = (state = initialState, action: Action): any => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_COMMENT: {
      const { comment } = payload;
      return {
        ...state,
        data: state.data.concat(comment),
      };
    }
    case EDIT_COMMENT: {
      const { comment: editComment } = payload;
      return {
        ...state,
        data: state.data.map((comment) => {
          if (comment.id === editComment.id) {
            return editComment;
          }
          return comment;
        }),
      };
    }
    case REMOVE_COMMENT: {
      const { commentRemoved } = payload;
      return {
        ...state,
        data: state.data.filter((comment) => comment.id !== commentRemoved.id),
      };
    }
    case LOAD_COMMENTS_SUCCESS: {
      const { comments } = payload;
      return {
        ...state,
        status: Status.SUCCEESS,
        data: comments,
      };
    }
    case LOAD_COMMENTS_IN_PROGRESS:
      return {
        ...state,
        status: Status.LOADING,
      };
    case LOAD_COMMENTS_FAILURE: {
      return {
        ...state,
        status: Status.FAILED,
      };
    }
    default:
      return state;
  }
};

export default commentsReducer;
