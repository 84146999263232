import { Team } from '../../services/model/Team';
import {
  LOAD_TEAM_FAILURE,
  LOAD_TEAM_IN_PROGRESS,
  LOAD_TEAM_SUCCESS,
} from '../actions/actionTypes';
import { InitialState, Status } from '../utillity';

const initialState: InitialState<Team[]> = {
  data: <Team[]>[],
  status: Status.IDLE,
  error: null,
};

const teamsReducer = (
  state = initialState,
  action: { type: string, payload: any }): any =>
{
  const { type, payload } = action;

  switch (type) {
    case LOAD_TEAM_SUCCESS: {
      const { team } = payload;
      const teamIndex = state.data.findIndex(t => t.id === team.id);
      const tmpArray = [...state.data];

      if (teamIndex === -1) {
        tmpArray.push(team);
      } else {
        tmpArray[teamIndex] = {...team};
      }

      return {
        ...state,
        status: Status.SUCCEESS,
        data: tmpArray,
      };
    }
    case LOAD_TEAM_IN_PROGRESS:
      return {
        ...state,
        status: Status.LOADING,
      };
    case LOAD_TEAM_FAILURE: {
      return {
        ...state,
        status: Status.FAILED,
      };
    }
    default:
      return state;
  }
};

export default teamsReducer;
