import { Roles } from '../../shared/utility';
import {
  CREATE_ROLES_SUCCESS,
  LOAD_ROLES_FAILURE,
  LOAD_ROLES_SUCCESS,
} from '../actions/actionTypes';
import { InitialState } from '../utillity';

const initialState: InitialState<Roles[]> = {
  data: <Roles[]>[],
  error: null,
};

const rolesReducer = (state = initialState, action: { type: string, payload: any }): any => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_ROLES_SUCCESS: {
      const { roles } = payload;
      return {
        ...state,
        data: roles,
      };
    }
    case LOAD_ROLES_SUCCESS: {
      const { roles } = payload;
      return {
        ...state,
        data: roles,
      };
    }
    case LOAD_ROLES_FAILURE: {
      return {
        ...state,
        error: 'Load roles failed.',
      };
    }

    default:
      return state;
  }
};

export default rolesReducer;
