// Auth action types
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_SIGNOUT = 'AUTH_SIGNOUT';
// Agency action types.
export const LOAD_AGENCY_IN_PROGRESS = 'LOAD_AGENCY_IN_PROGRESS ';
export const LOAD_AGENCY_SUCCESS = 'LOAD_AGENCY_SUCCESS';
export const LOAD_AGENCY_FAILURE = 'LOAD_AGENCY_FAILURE';
export const UPDATE_AGENCY_FAILURE = 'UPDATE_AGENCY_FAILURE';
export const UPDATE_AGENCY_IN_PROGRESS = 'UPDATE_AGENCY_IN_PROGRESS';
export const UPDATE_AGENCY_SUCCESS = 'UPDATE_AGENCY_SUCCESS';
// Account action types.
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const EDIT_ACCOUNT = 'EDIT_ACCOUNT';
export const REMOVE_ACCOUNT = 'REMOVE_ACCOUNT';
export const RESET_ACCOUNTS = 'RESET_ACCOUNTS';
export const LOAD_ACCOUNTS_IN_PROGRESS = 'LOAD_ACCOUNTS_IN_PROGRESS';
export const LOAD_ACCOUNTS_SUCCESS = 'LOAD_ACCOUNTS_SUCCESS';
export const LOAD_SPECIFIC_ACCOUNTS_SUCCESS = 'LOAD_SPECIFIC_ACCOUNTS_SUCCESS';
export const LOAD_ACCOUNTS_FAILURE = 'LOAD_ACCOUNTS_FAILURE';
// Set Account action types.
export const SET_ACCOUNT_SUCCESS = 'SET_ACCOUNT_SUCCESS';
export const SET_ACCOUNT_FAILURE = 'SET_ACCOUNT_FAILURE';
// Invitations action types.
export const CREATE_INVITATION = 'CREATE_INVITATION';
export const CREATE_INVITATION_IN_PROGRESS = 'CREATE_INVITATION_IN_PROGRESS';
export const CREATE_INVITATION_FAILURE = 'CREATE_INVITATION_FAILURE';
export const LOAD_INVITATIONS_IN_PROGRESS = 'LOAD_INVITATIONS_IN_PROGRESS';
export const LOAD_INVITATIONS_SUCCESS = 'LOAD_INVITATIONS_SUCCESS';
export const LOAD_INVITATIONS_FAILURE = 'LOAD_INVITATIONS_FAILURE';
export const LOAD_INVITATION_INFO_IN_PROGRESS =
  'LOAD_INVITATION_INFO_IN_PROGRESS';
export const LOAD_INVITATION_INFO_SUCCESS = 'LOAD_INVITATION_INFO_SUCCESS';
export const LOAD_INVITATION_INFO_FAILURE = 'LOAD_INVITATION_INFO_FAILURE';
export const REVOKE_INVITATION_IN_PROGRESS = 'REVOKE_INVITATION_IN_PROGRESS';
export const REVOKE_INVITATION_FAILURE = 'REVOKE_INVITATION_FAILURE';
export const REVOKE_INVITATION_SUCCESS = 'REVOKE_INVITATION_SUCCESS';
// Modal
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const TOGGLE_CLIENT_MODAL = 'TOGGLE_CLIENT_MODAL';
export const TOGGLE_INVITATION_MODAL = 'TOGGLE_INVITATION_MODAL';
export const TOGGLE_REQUEST_DETAIL_MODAL = 'TOGGLE_REQUEST_DETAIL_MODAL';
export const TOGGLE_SUBMIT_REQUEST_CONFIRMATION_MODAL =
  'TOGGLE_SUBMIT_REQUEST_CONFIRMATION_MODAL';
export const TOGGLE_REQUEST_FORM_PREVIEW_MODAL =
  'TOGGLE_REQUEST_FORM_PREVIEW_MODAL';
// Create Label Modal
export const TOGGLE_CREATE_LABEL_MODAL = 'TOGGLE_CREATE_LABEL_MODAL';
// Add Label Modal
export const TOGGLE_ADD_LABEL_MODAL = 'TOGGLE_CREATE_LABEL_MODAL';
// NAV Modal
export const TOGGLE_NAV_MODAL = 'TOGGLE_NAV_MODAL';
// TIME LOGGING MODAL
export const TOGGLE_TIME_LOGGING_MODAL = 'TOGGLE_TIME_LOGGING_MODAL';
// DELETE REQUEST TYPE MODAL
export const TOGGLE_IMAGE_PREVIEW_MODAL = 'TOGGLE_IMAGE_PREVIEW_MODAL';
// OPEN IMAGE PREVIEW
export const TOGGLE_DELETE_REQUEST_TYPE_MODAL =
  'TOGGLE_DELETE_REQUEST_TYPE_MODAL';
// SUBMIT REQUEST MODAL
export const TOGGLE_SUBMIT_REQUEST_MODAL = 'TOGGLE_SUBMIT_REQUEST_MODAL';
// OPEN TOAST MESSAGE MODAL
export const TOGGLE_TOAST_MESSAGE_MODAL = 'TOGGLE_TOAST_MESSAGE_MODAL';

/* Clients */
export const CREATE_CLIENT = 'CREATE_CLIENT';
export const EDIT_CLIENT = 'EDIT_CLIENT';
export const REMOVE_CLIENT = 'REMOVE_CLIENT';
export const LOAD_CLIENTS_IN_PROGRESS = 'LOAD_CLIENTS_IN_PROGRESS';
export const LOAD_CLIENTS_SUCCESS = 'LOAD_CLIENTS_SUCCESS';
export const LOAD_CLIENTS_FAILURE = 'LOAD_CLIENTS_FAILURE';
/* Teams */
export const LOAD_TEAM_IN_PROGRESS = 'LOAD_TEAM_IN_PROGRESS';
export const LOAD_TEAM_SUCCESS = 'LOAD_TEAM_SUCCESS';
export const LOAD_TEAM_FAILURE = 'LOAD_TEAM_FAILURE';
/* Requests */
export const CREATE_REQUEST_FAILURE = 'CREATE_REQUEST_FAILURE';
export const CREATE_REQUEST_IN_PROGRESS = 'CREATE_REQUEST_IN_PROGRESS';
export const CREATE_REQUEST_SUCCESS = 'CREATE_REQUEST_SUCCESS';
export const EDIT_REQUEST = 'EDIT_REQUEST';
export const REMOVE_REQUEST = 'REMOVE_REQUEST';
export const LOAD_REQUESTS_IN_PROGRESS = 'LOAD_REQUESTS_IN_PROGRESS';
export const LOAD_REQUESTS_SUCCESS = 'LOAD_REQUESTS_SUCCESS';
export const LOAD_REQUESTS_FAILURE = 'LOAD_REQUESTS_FAILURE';
export const LOAD_REQUEST_IN_PROGRESS = 'LOAD_REQUEST_IN_PROGRESS';
export const LOAD_REQUEST_SUCCESS = 'LOAD_REQUEST_SUCCESS';
export const LOAD_REQUEST_FAILURE = 'LOAD_REQUEST_FAILURE';
export const SELECT_REQUEST = 'SELECT_REQUEST';
export const SELECT_ALL_REQUEST = 'SELECT_ALL_REQUEST';
export const UNSELECT_REQUEST = 'UNSELECT_REQUEST';
export const UPDATE_TOTAL_ITEMS = 'UPDATE_TOTAL_ITEMS';
export const UPDATE_TOTAL_PAGES = 'UPDATE_TOTAL_PAGES';
export const UPDATE_CURRENT_PAGE = 'UPDATE_CURRENT_PAGE';
export const CLEAR_SELECTED_REQUESTS = 'CLEAR_SELECTED_REQUESTS';
/* Progress */
export const LOAD_PROGRESS_IN_PROGRESS = 'LOAD_PROGRESS_IN_PROGRESS';
export const LOAD_PROGRESS_SUCCESS = 'LOAD_PROGRESS_SUCCESS';
export const LOAD_PROGRESS_FAILURE = 'LOAD_PROGRESS_FAILURE';
/* StatusFlags */
export const LOAD_STATUS_FLAGS_SUCCESS = 'LOAD_STATUS_FLAGS_SUCCESS';
export const LOAD_STATUS_FLAGS_FAILURE = 'LOAD_STATUS_FLAGS_FAILURE';
export const LOAD_STATUS_FLAGS_IN_PROGRESS = 'LOAD_STATUS_FLAGS_IN_PROGRESS';
export const CREATE_STATUS_FLAG_SUCCESS = 'CREATE_STATUS_FLAG_SUCCESS';
export const CREATE_STATUS_FLAG_FAILURE = 'CREATE_STATUS_FLAG_FAILURE';
export const REMOVE_STATUS_FLAG = 'REMOVE_STATUS_FLAG';
export const UPDATE_STATUS_FLAGS_FAILURE = 'UPDATE_STATUS_FLAGS_FAILURE';
export const UPDATE_STATUS_FLAGS_SUCCESS = 'UPDATE_STATUS_FLAGS_SUCCESS';
/* RequestTypes */
export const CREATE_REQUEST_TYPE = 'CREATE_REQUEST_TYPE';
export const EDIT_REQUEST_TYPE = 'EDIT_REQUEST_TYPE';
export const REMOVE_REQUEST_TYPE = 'REMOVE_REQUEST_TYPE';
export const LOAD_REQUEST_TYPES_SUCCESS = 'LOAD_REQUEST_TYPES_SUCCESS';
export const LOAD_REQUEST_TYPES_FAILURE = 'LOAD_REQUEST_TYPES_FAILURE';
/* Labels */
export const LOAD_LABELS_SUCCESS = 'LOAD_LABELS_SUCCESS';
export const LOAD_LABELS_FAILURE = 'LOAD_LABELS_FAILURE';
export const LOAD_LABELS_IN_PROGRESS = 'LOAD_LABELS_IN_PROGRESS';
export const CREATE_LABEL_IN_PROGRESS = 'CREATE_LABEL_IN_PROGRESS';
export const CREATE_LABEL_SUCCESS = 'CREATE_LABEL_SUCCESS';
export const CREATE_LABEL_FAILURE = 'CREATE_LABEL_FAILURE';
export const UPDATE_LABEL = 'UPDATE_LABEL';
export const REMOVE_LABEL = 'REMOVE_LABEL';

/* Notifications - Snackbar */
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
/* Canned Responses */
export const CREATE_CANNED_RESPONSE = 'CREATE_CANNED_RESPONSE';
export const EDIT_CANNED_RESPONSE = 'EDIT_CANNED_RESPONSE';
export const REMOVE_CANNED_RESPONSE = 'REMOVE_CANNED_RESPONSE';
export const LOAD_CANNED_RESPONSES_IN_PROGRESS =
  'LOAD_CANNED_RESPONSES_IN_PROGRESS';
export const LOAD_CANNED_RESPONSES_SUCCESS = 'LOAD_CANNED_RESPONSES_SUCCESS';
export const LOAD_CANNED_RESPONSES_FAILURE = 'LOAD_CANNED_RESPONSES_FAILURE';
/* Comments */
export const CREATE_COMMENT = 'CREATE_COMMENT';
export const EDIT_COMMENT = 'EDIT_COMMENT';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';
export const LOAD_COMMENTS_IN_PROGRESS = 'LOAD_COMMENTS_IN_PROGRESS';
export const LOAD_COMMENTS_SUCCESS = 'LOAD_COMMENTS_SUCCESS';
export const LOAD_COMMENTS_FAILURE = 'LOAD_COMMENTS_FAILURE';
/* TimeLogs */
export const CREATE_TIME_LOG = 'CREATE_TIME_LOG';
export const EDIT_TIME_LOG = 'EDIT_TIME_LOG';
export const REMOVE_TIME_LOG = 'REMOVE_TIME_LOG';
export const LOAD_TIME_LOGS_IN_PROGRESS = 'LOAD_TIME_LOGS_IN_PROGRESS';
export const LOAD_TIME_LOGS_SUCCESS = 'LOAD_TIME_LOGS_SUCCESS';
export const LOAD_TIME_LOGS_FAILURE = 'LOAD_TIME_LOGS_FAILURE';
/* PrivateNotes */
export const CREATE_PRIVATE_NOTE = 'CREATE_PRIVATE_NOTE';
export const EDIT_PRIVATE_NOTE = 'EDIT_PRIVATE_NOTE';
export const REMOVE_PRIVATE_NOTE = 'REMOVE_PRIVATE_NOTE';
export const LOAD_PRIVATE_NOTES_IN_PROGRESS = 'LOAD_PRIVATE_NOTES_IN_PROGRESS';
export const LOAD_PRIVATE_NOTES_SUCCESS = 'LOAD_PRIVATE_NOTES_SUCCESS';
export const LOAD_PRIVATE_NOTES_FAILURE = 'LOAD_PRIVATE_NOTES_FAILURE';
/* Roles */
export const CREATE_ROLES_SUCCESS = 'CREATE_ROLES_SUCCESS';
export const LOAD_ROLES_SUCCESS = 'LOAD_ROLES_SUCCESS';
export const LOAD_ROLES_FAILURE = 'LOAD_ROLES_FAILURE';
/* Form fields */
export const LOAD_FORM_FIELDS_IN_PROGRESS = 'LOAD_FORM_FIELDS_IN_PROGRESS';
export const LOAD_FORM_FIELDS_SUCCESS = 'LOAD_FORM_FIELDS_SUCCESS';
export const LOAD_FORM_FIELDS_FAILURE = 'LOAD_FORM_FIELDS_FAILURE';
/* Request access permissions */
export const LOAD_ACCESS_REQUEST_PERMISSION_IN_PROGRESS = 'LOAD_ACCESS_REQUEST_PERMISSION_IN_PROGRESS';
export const LOAD_ACCESS_REQUEST_PERMISSION_SUCCESS = 'LOAD_ACCESS_REQUEST_PERMISSION_SUCCESS';
export const LOAD_ACCESS_REQUEST_PERMISSION_FAILURE = 'LOAD_ACCESS_REQUEST_PERMISSION_FAILURE';
/* Media objects */
export const CACHE_MEDIA_OBJECT = 'CACHE_MEDIA_OBJECT';
/* Request Priorities */
export const LOAD_REQUEST_PRIORITY_IN_PROGRESS = 'LOAD_REQUEST_PRIORITY_IN_PROGRESS';
export const LOAD_REQUEST_PRIORITY_SUCCESS = 'LOAD_REQUEST_PRIORITY_SUCCESS';
export const LOAD_REQUEST_PRIORITY_FAILURE = 'LOAD_REQUEST_PRIORITY_FAILURE';
