/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { DateTime } from 'luxon';

import LocalStorageService from '../store/LocalStorageServices';

export const fromStringToDate = (date: string): Date => DateTime.fromISO(date).toJSDate();
export const formatCreatedDate = (date: string): string =>
  DateTime.fromISO(date).toFormat('DDD');

export const formatNaN = (val: string | number): string | number => (Number.isNaN(val) ? '' : val);

export const getTotalPages = (data: Record<string, any>): number =>
  Number(data?.['hydra:view']['hydra:last']?.split('page=')[1]);

export const tooltipTitle = (title: string, characterLength: number): string =>
  title.length > characterLength ? title : '';

export const TOOLTIP_TEAM_NAME_LENGTH = 19;
export const TOOLTIP_CLIENT_NAME_LENGTH = 35;
export const TOOLTIP_DELAY = 200;

export const NOW = new Date().toISOString();

export const ENTER_KEY = 'Enter';
export const TAB_KEY = 'Tab';
export const BACKSPACE_KEY = 'backspace';

export enum Roles {
  ROLE_AGENCY_ADMIN = 'ROLE_AGENCY_ADMIN',
  ROLE_AGENCY_MEMBER = 'ROLE_AGENCY_MEMBER',
  ROLE_CLIENT_MEMBER = 'ROLE_CLIENT_MEMBER',
  ROLE_USER = 'ROLE_USER',
}

export interface IDropdown {
  currentDropdown: false | number;
  setCurrentDropdown: (prevState: false | number) => void;
  search: string;
}

const adminRole = (role: Roles) =>
  [Roles.ROLE_AGENCY_ADMIN, Roles.ROLE_AGENCY_MEMBER].includes(role);
export const isAgencyRole = (roles: Roles[]): boolean =>
  roles ? roles.some(adminRole) : false;
export const isAgencyAdminRole = (roles: Roles[] | undefined): boolean =>
  roles ? roles.includes(Roles.ROLE_AGENCY_ADMIN) : false;
export const isClientRole = (roles: Roles[]): boolean =>
  roles ? roles.includes(Roles.ROLE_CLIENT_MEMBER) : false;
export const checkParams = (...params: any[]): boolean =>
  params.reduce((previous, current) =>
    previous ? previous && !!current : false
  );

export const getInitials = (name: string, maxLength = 3): string => {
  const chars = name?.trim().split('');

  if (name?.length <= maxLength) return name;

  if (name?.includes("Unassigned")) return '?';

  const initials: string[] = [];

  if (chars) {
    // @ts-ignore
    // eslint-disable-next-line no-restricted-syntax
    for (const [index, char] of chars.entries()) {
      if (
        char.toLowerCase() !== char ||
        !chars[index - 1] ||
        /\s/.test(chars[index - 1])
      ) {
        initials.push(char);

        if (initials.length === maxLength) break;
      }
    }
  }

  return initials.join('');
};

export const stringToColour = (str: string): string => {
  let hash = 0;
  for (let i = 0; i < str?.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i += 1) {
    // eslint-disable-next-line no-bitwise
    const value = (hash >> (i * 8)) & 0xff;
    colour += `00${value.toString(16)}`.substr(-2);
  }
  return colour;
};

export const capitalize = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const extractId = (str: string): string =>
  str ? str.substring(str.lastIndexOf('/') + 1) : '';
export const not = (list1: string[], list2: string[]): string[] =>
  list1.filter((a) => !list2.some((b) => a === b));

export const debounce = <F extends (...args: any) => any>(
  func: F,
  waitFor: number,
): (...args: Parameters<F>) => ReturnType<F> => {
  let timeout: any = 0;
  const debounced = (...args: any) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), waitFor);
  };
  return debounced as (...args: Parameters<F>) => ReturnType<F>;
};

export const timezones = {
    'UTC-8': 'Pacific Standard Time',
    'UTC-11' : 'Midway Islands Time',
    'UTC-10': 'Hawaii Standard Time',
    'UTC-9': 'Alaska Standard Time',
    'UTC-7': 'Mountain Standard Time',
    'UTC-6': 'Central Standard Time',
    'UTC-5': 'Eastern Standard Time',
    'UTC-4' : 'Puerto Rico and US Virgin Islands Time',
    'UTC-3:30' : 'Canada Newfoundland Time',
    'UTC-3' : 'Argentina Standard Time',
    'UTC-1': 'Central African Time',
    'UTC' : 'Universal Coordinated Time',
    'UTC+1' : 'European Central Time',
    'UTC+2' : 'Eastern European Time',
    'UTC+3' : 'Eastern African Time',
    'UTC+3:30' : 'Middle East Time',
    'UTC+4' : 'Near East Time',
    'UTC+5' : 'Pakistan Lahore Time',
    'UTC+5:30' : 'India Standard Time	',
    'UTC+6' : 'Bangladesh Standard Time',
    'UTC+7' : 'Vietnam Standard Time',
    'UTC+8' : 'China Taiwan Time',
    'UTC+9' : 'Japan Standard Time',
    'UTC+9:30' : 'Australia Central Time',
    'UTC+10' : 'Australia Eastern Time',
    'UTC+11' : 'Solomon Standard Time',
    'UTC+12' : 'New Zealand Standard Time',
};

export  const formatTimezone = (date: string): DateTime => {
  const timezone = LocalStorageService.getLoggedAccountTimezone();

  return DateTime.local().isInDST ?
    DateTime.fromISO(date, {zone: timezone!}).plus({hour: 1}) :
    DateTime.fromISO(date, {zone: timezone!});
};

export const formatDate = (
  date: string,
  withTime = false,
  dateFormat = 'MM/dd/yyyy'
): string =>
  date
    ? formatTimezone(date).toFormat(
      withTime ? "MM/dd/yyyy' at 'hh:mm a" : dateFormat)
    : '';

export const getFileExtension = (fileName: string ): string => fileName.substr(fileName.lastIndexOf('.') + 1);

enum ImageFileTypes {
  JPG = 'jpg',
  JPEG = 'jpeg',
  PNG = 'png',
  WEBP = 'webp'
}

export const isFileImage = (extension: string): boolean => {
  const imageTypeArray: string[] = [ImageFileTypes.PNG, ImageFileTypes.JPG, ImageFileTypes.JPEG, ImageFileTypes.WEBP];
  return imageTypeArray.includes(extension);
};

export const isFilePdf = (extension: string): boolean => extension.includes('pdf');

export const unauthenticatedRoutes = [
  '/accounts/change-password',
  '/accept-invitation',
];

export const isRouteMatch = (pathname: string): string|undefined => unauthenticatedRoutes.find(route => pathname.includes(route));

export const reduceFileName = (fileName: string): string => fileName?.slice(14);

export const exportFileName = (fileName: string): string => {
  let downloadFile = fileName;

  if (downloadFile[0] === '"' && downloadFile[downloadFile.length - 1] === '"')
    downloadFile = downloadFile.slice(1, -1);

  if(downloadFile[downloadFile.length - 1] === '_')
    downloadFile = downloadFile.slice(-1);

  downloadFile = reduceFileName(downloadFile).replaceAll(" ","");

  return downloadFile;
};

const pages = new Map ([
  ['/auth', 'Log In'],
  ['/request', 'Requests'],
  ['/clients', 'Clients'],
  ['/team', 'Team'],
  ['/settings/input-forms', 'Input Forms Settings'],
  ['/settings/edit-input-form', 'Edit Input Form'],
  ['/settings/add-input-form', 'Add Input Form'],
  ['/settings/canned-responses', 'Canned Responses Settings'],
  ['/settings/edit-canned-response', 'Edit Canned Responses'],
  ['/settings/add-canned-response', 'Add Canned Responses'],
  ['/settings/status-flags', 'Status Flags Settings'],
  ['/settings/add-status-flag', 'Add Status Flag'],
  ['/settings/agency', 'Agency Settings'],
  ['/agency-edit-member', 'My Profile'],
  ['/my-preferences', 'My Preferences'],
  ['/client-edit-member', 'Edit Member'],
  ['/reset-password', 'Reset Password'],
  ['/edit-client-company', 'Edit Company'],
  ['/accounts/change-password', 'Change Password'],
  ['/accept-invitation', 'Accept Invitation'],
]);

const findPageTitle = (path: string): string => {
  const title = pages.get(path);
  if (title)
    return title;

  let partialMatchingTitle;
  pages.forEach((value, key) => {
    if (path.startsWith(key)) {
      partialMatchingTitle = value;
    }
  });

  return partialMatchingTitle || '';
};

export const changePageTitle = (location: string, agencyName: string, requestTitle = ''): string =>
  `Sideline - ${agencyName ? `${agencyName  } -` : ''}  ${requestTitle || findPageTitle(location)}`;

export const isReqParamFound = (): boolean | string => {
  const param = window.location.search;
  return param && param.includes('requestId');
};

export const removeRequestIdParam = (): void => {
  // @ts-ignore
  const url = new URL(window.location);
  url.searchParams.delete('requestId');
  window.history.pushState(null, '', url.toString());
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const uploadHandleLimit = (handleUpload: any, e: any): void => {
  const fileInput = e.target.files;
  if (!fileInput.length) return;

  let fileSize = 0;

  Array.from(fileInput).forEach((file: any) => {
    fileSize += file.size;
  });
  const fileMb = fileSize / 1024 ** 2;

  if (fileMb > 16)
    // eslint-disable-next-line no-alert
    window.alert('We\'re sorry, but the file you\'re trying to upload exceeds the maximum allowed size of 16 MB.\n' +
      '\n');
  else
    handleUpload(e);
};
