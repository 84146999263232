// Access and refresh token
const ACCESS_TOKEN = 'access-token';
const REFRESH_TOKEN = 'refresh_token';
// Logged account
const LOGGED_ACCOUNT_ID = 'logged_account_id';
const LOGGED_ACCOUNT_TEAM_ID = 'logged_account_team_id';
const LOGGED_ACCOUNT_TIMEZONE = 'logged_account_timezone';
// Edit member account
const EDIT_MEMBER_ACCOUNT_ID = 'edit_member_account_id';
const EDIT_MEMBER_ACCOUNT_COMPANY = 'edit_member_account_company';
// Delete member account
const AGENCY_REMOVE_MEMBER_NAME = 'agency_member_delete_name';
// Agency delete tokens
const AGENCY_DELETE_ACCOUNT_ID = 'agency_delete_account_id';
// Agency open modal tokens
const AGENCY_OPEN_MODAL_ID = 'agency_open_modal_id';
// Edit client information
const CLIENT_ID = 'client_id';
const CLIENT_COMPANY_NAME = 'client_company_name';
// Delete Client Member
const CLIENT_MEMBER_DELETE_ID = 'client_member_delete_id';
// Edit Canned Response
const EDIT_CANNED_RESPONSE_ID = 'edit_canned_response_id';
const EDIT_CANNED_RESPONSE_TITLE = 'edit_canned_response_title';
const EDIT_CANNED_RESPONSE_MESSAGE = 'edit_canned_response_message';
// Selected Client Team id
const CLIENT_TEAM_ID = 'client_team_id';
// Selected default view
const DEFAULT_VIEW = 'default_view';

// LocalStorage service.
const LocalStorageService = {
  // Access and refresh token
  setAccessToken: (token: string): void =>
    localStorage.setItem(ACCESS_TOKEN, token),
  getAccessToken: (): string | null => localStorage.getItem(ACCESS_TOKEN),
  setRefreshToken: (token: string): void =>
    localStorage.setItem(REFRESH_TOKEN, token),
  getRefreshToken: (): string | null => localStorage.getItem(REFRESH_TOKEN),
  clearToken: (): void => {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
  },

  // Logged account information
  setLoggedAccountId: (id: string): void =>
    localStorage.setItem(LOGGED_ACCOUNT_ID, id),
  setLoggedAccountTeamId: (id: string): void =>
    localStorage.setItem(LOGGED_ACCOUNT_TEAM_ID, id),
  setLoggedAccountTimezone: (timezone: string): void =>
    localStorage.setItem(LOGGED_ACCOUNT_TIMEZONE, timezone),
  getLoggedAccountId: (): string | null =>
    localStorage.getItem(LOGGED_ACCOUNT_ID),
  getLoggedAccountTeamId: (): string | null =>
    localStorage.getItem(LOGGED_ACCOUNT_TEAM_ID),
  getLoggedAccountTimezone: (): string | null =>
    localStorage.getItem(LOGGED_ACCOUNT_TIMEZONE),

  // Edit member account
  setEditMemberId: (id: string): void =>
    localStorage.setItem(EDIT_MEMBER_ACCOUNT_ID, id),
  setEditMemberCompany: (company: string): void =>
    localStorage.setItem(EDIT_MEMBER_ACCOUNT_COMPANY, company),
  getEditMemberId: (): string | null =>
    localStorage.getItem(EDIT_MEMBER_ACCOUNT_ID),
  getEditMemberCompany: (): string | null =>
    localStorage.getItem(EDIT_MEMBER_ACCOUNT_COMPANY),
  clearEditMemberData: (): void => {
    localStorage.removeItem(EDIT_MEMBER_ACCOUNT_ID);
    localStorage.removeItem(EDIT_MEMBER_ACCOUNT_COMPANY);
  },

  // Agency delete token setters and getters
  getAgencyDeleteId: (): string | null =>
    localStorage.getItem(AGENCY_DELETE_ACCOUNT_ID),
  setAgencyDeleteId: (id: string): void =>
    localStorage.setItem(AGENCY_DELETE_ACCOUNT_ID, id),
  clearAgencyDeleteTokens: (): void =>
    localStorage.removeItem(AGENCY_DELETE_ACCOUNT_ID),

  // Agency delete member setters and getters
  getRemoveMemberName: (): string | null =>
    localStorage.getItem(AGENCY_REMOVE_MEMBER_NAME),
  setRemoveMemberName: (member_name: string): void =>
    localStorage.setItem(AGENCY_REMOVE_MEMBER_NAME, member_name),
  clearRemoveMemberName: (): void =>
    localStorage.removeItem(AGENCY_REMOVE_MEMBER_NAME),

  // Agency toggle modal get/set
  getAgencyModalId: (): string | null =>
    localStorage.getItem(AGENCY_OPEN_MODAL_ID),
  setAgencyModalId: (id: string): void =>
    localStorage.setItem(AGENCY_OPEN_MODAL_ID, id),

  // Edit client information
  setClientId: (id: string): void => localStorage.setItem(CLIENT_ID, id),
  setClientCompanyName: (name: string): void =>
    localStorage.setItem(CLIENT_COMPANY_NAME, name),
  getClientId: (): string | null => localStorage.getItem(CLIENT_ID),
  getClientCompanyName: (): string | null =>
    localStorage.getItem(CLIENT_COMPANY_NAME),
  clearClientTokens: (): void => localStorage.removeItem(CLIENT_ID),

  // Client member delete token setters and getters
  getClientMemberDeleteId: (): string | null =>
    localStorage.getItem(CLIENT_MEMBER_DELETE_ID),
  setClientMemberDeleteId: (id: string): void =>
    localStorage.setItem(CLIENT_MEMBER_DELETE_ID, id),
  clearClientMemberDeleteTokens: (): void =>
    localStorage.removeItem(CLIENT_MEMBER_DELETE_ID),

  // Edit canned response
  setCannedResponseId: (id: string): void =>
    localStorage.setItem(EDIT_CANNED_RESPONSE_ID, id),
  setCannedResponseTitle: (title: string): void =>
    localStorage.setItem(EDIT_CANNED_RESPONSE_TITLE, title),
  setCannedResponseMessage: (message: string): void =>
    localStorage.setItem(EDIT_CANNED_RESPONSE_MESSAGE, message),
  getCannedResponseId: (): string | null =>
    localStorage.getItem(EDIT_CANNED_RESPONSE_ID),
  getCannedResponseTitle: (): string | null =>
    localStorage.getItem(EDIT_CANNED_RESPONSE_TITLE),
  getCannedResponseMessage: (): string | null =>
    localStorage.getItem(EDIT_CANNED_RESPONSE_MESSAGE),
  clearCannedResponseData: (): void => {
    localStorage.removeItem(EDIT_CANNED_RESPONSE_ID);
    localStorage.removeItem(EDIT_CANNED_RESPONSE_TITLE);
    localStorage.removeItem(EDIT_CANNED_RESPONSE_MESSAGE);
  },

  // Client team
  setClientTeamId: (id: string): void =>
    localStorage.setItem(CLIENT_TEAM_ID, id),
  getClientTeamId: (): string | null => localStorage.getItem(CLIENT_TEAM_ID),

  // Default view
  setDefaultView: (view: string): void =>
    localStorage.setItem(DEFAULT_VIEW, view),
  getDefaultView: (): string | null => localStorage.getItem(DEFAULT_VIEW)
};

export default LocalStorageService;
