import { patch } from '../../config/api';
import { AgencyUpdateRequest } from '../request/AgencyUpdateRequest';

// API Endpoints
const AGENCIES_URL = '/agencies';

const Agencies = {
  patch: (id: number, params: Partial<AgencyUpdateRequest>): any => patch(`${AGENCIES_URL}/${id}`, params),
};

export default Agencies;
