import { StatusFlag } from '../../services/model/Request';
import {
  CREATE_STATUS_FLAG_SUCCESS,
  LOAD_STATUS_FLAGS_FAILURE,
  LOAD_STATUS_FLAGS_IN_PROGRESS,
  LOAD_STATUS_FLAGS_SUCCESS,
  REMOVE_STATUS_FLAG,
  UPDATE_STATUS_FLAGS_FAILURE,
  UPDATE_STATUS_FLAGS_SUCCESS
} from '../actions/actionTypes';
import {InitialState, Status} from '../utillity';

const initialState: InitialState<StatusFlag[]> = {
  data: <StatusFlag[]>[],
};

const statusFlagsReducer = (state = initialState, action: { type: string, payload: any }): any => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_STATUS_FLAGS_IN_PROGRESS: {
      return {
        ...state,
        status: Status.LOADING,
      };
    }
    case LOAD_STATUS_FLAGS_SUCCESS: {
      const { statusFlags } = payload;
      return {
        ...state,
        data: statusFlags,
      };
    }
    case LOAD_STATUS_FLAGS_FAILURE: {
      return {
        ...state,
        error: 'Load status flags failed',
      };
    }
    case CREATE_STATUS_FLAG_SUCCESS: {
      const { statusFlag } = payload;
      return {
        ...state,
        data: state.data.concat(statusFlag)
      };
    }
    case REMOVE_STATUS_FLAG: {
      const { id } = payload;
      return {
        ...state,
        data: state.data.filter(
          (statusFlag) => statusFlag.id !== id
        ),
      };
    }
    case UPDATE_STATUS_FLAGS_SUCCESS: {
      const { statusFlag } = payload;
      return {
        ...state,
        data: state.data.map((status) =>
          status.id === statusFlag.id ? statusFlag : status
        )
      };
    }
    case UPDATE_STATUS_FLAGS_FAILURE: {
      return {
        ...state,
        error: 'Status flag update failed',
      };
    }
    default:
      return state;
  }
};

export default statusFlagsReducer;
