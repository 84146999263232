import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import classNames from "classnames";

import Avatar from '../../../components/Avatar';
import Spinner from "../../../components/spinner/Spinner";
import { ENTER_KEY, isAgencyAdminRole, isAgencyRole, isClientRole } from '../../../shared/utility';
import LocalStorageService from '../../../store/LocalStorageServices';
import {
  getAccountById,
  getAccountsLoadingStatus,
} from '../../../store/selectors/accounts';
import {getAgency} from "../../../store/selectors/agency";
import getRolesData from '../../../store/selectors/roles';
import { loadAccounts } from '../../../store/thunks/accounts';
import { signout } from '../../../store/thunks/auth';
import backOffice from "../../../store/thunks/backOffice";
import { Status } from '../../../store/utillity';

import './Nav.scss';
import './UserAvatar.scss';

interface HeaderUserAvatarProps {
  isRequestOpen?: boolean;
};

const UserAvatar: React.FC<HeaderUserAvatarProps> = ({
 isRequestOpen
}) => {
  const roles = useSelector((state) => getRolesData(state));
  const [isModalOpen, toggleOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const logout = () => {
    dispatch(signout());
  };
  const content = document.querySelector('.region--content');
  const loggedAccountID = LocalStorageService.getLoggedAccountId();
  const accountStatus = useSelector((state) => getAccountsLoadingStatus(state));
  const node = useRef<HTMLDivElement>(null);
  const agency = useSelector((state) => getAgency(state));

  const loggedAccount = useSelector((state) =>
    getAccountById(state, loggedAccountID || '')
  );

  const [isLoading, setIsLoading] = useState(false);

  const goToLink = (link: string) => {
    LocalStorageService.setEditMemberId(loggedAccountID as string);
    history.push(link);
    toggleOpen(false);
  };

  const closeOnContentClick = useCallback(() => {
    if (isModalOpen) {
      toggleOpen(false);
    }
  }, [isModalOpen, toggleOpen]);

  const gotToBillingPortal = () => {
    if (agency.stripeId) {
      setIsLoading(true);
      dispatch<any>(backOffice(agency.stripeId))
        .then((response: any) => {
          setIsLoading(false);
          window.open(response);
        });
    }
  };

  useEffect(() => {
    content?.addEventListener('click', () => {
      closeOnContentClick();
    });
    if (accountStatus === Status.IDLE || accountStatus === Status.FAILED) {
      dispatch(loadAccounts());
    }
  }, [accountStatus, closeOnContentClick, content, dispatch]);

  const onClickOutside = (e: any) => {
    if (!(node.current as any).contains(e.target)) {
      toggleOpen(false);
    }
  };

  useEffect(() => {
    // add when mounted
    if(isModalOpen) {
      document.addEventListener("mousedown", onClickOutside);
    }
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", onClickOutside);
    };
  });

  return (
    <div
      className="nav--user"
      ref={node}
    >
      <div
        className="user__avatar border"
        onClick={() => {
          toggleOpen(!isModalOpen);
        }}
        onKeyUp={(event: any) => {
          if(event.key === ENTER_KEY)
            toggleOpen(!isModalOpen);
        }}
        role="button"
        tabIndex={!isRequestOpen ? 0 : -1}
      >
        <Avatar className="avatar--big" user={loggedAccount} />
      </div>
      <ul className={isModalOpen ? 'nav__list open' : 'nav__list'}>
        { isAgencyRole(roles) || isClientRole(roles) ? (
          <>
            <li className="nav__item">
              <span
                className="nav__link"
                onClick={() => {goToLink('/agency-edit-member');}}
                onKeyUp={(event: any) => {
                  if(event.key === 'Enter')
                    goToLink('/agency-edit-member');
                }}
                role="button"
                tabIndex={isModalOpen && !isRequestOpen ? 0 : -1}
              >
                My Profile
              </span>
            </li>
            <li className="nav__item">
              <span
                className="nav__link"
                onClick={() => {goToLink('/my-preferences');}}
                onKeyUp={(event: any) => {
                  if(event.key === ENTER_KEY)
                    goToLink('/my-preferences');
                }}
                role="button"
                tabIndex={isModalOpen && !isRequestOpen ? 0 : -1}
              >
                My Preferences
              </span>
            </li>
          </>
        ) : null }
        {
          isAgencyAdminRole(roles) &&
          <li className={classNames("nav__item",
          isLoading && 'flex flex--justify--center')}>
            <span
              className={classNames("nav__link",
                isLoading && 'padding--top padding--bottom'
              )}
              onClick={gotToBillingPortal}
              onKeyUp={(event: any) => {
                if(event.key === ENTER_KEY)
                  gotToBillingPortal();
              }}
              role="button"
              tabIndex={isModalOpen && !isRequestOpen ? 0 : -1}
            >
              {isLoading ? <Spinner/> : 'Billing Information'}
            </span>
          </li>
        }
        <li className="nav__item">
          <a className="nav__link"
            href="auth"
            onClick={logout}
            tabIndex={isModalOpen && !isRequestOpen ? 0 : -1}
          >
            Sign Out
          </a>
        </li>
      </ul>
    </div>
  );
};

export default UserAvatar;
