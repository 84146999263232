import { Action } from '../../services/model/Action';
import Client from '../../services/model/Client';
import {
  CREATE_CLIENT,
  EDIT_CLIENT,
  LOAD_CLIENTS_FAILURE,
  LOAD_CLIENTS_IN_PROGRESS,
  LOAD_CLIENTS_SUCCESS,
  REMOVE_CLIENT,
} from '../actions/actionTypes';
import { InitialState, Status } from '../utillity';

const initialState: InitialState<Client[]> = {
  data: <Client[]>[],
  status: Status.IDLE,
  error: null,
};
const clientsReducer = (state = initialState, action: Action): any => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_CLIENT: {
      const { client } = payload;
      return {
        ...state,
        status: Status.SUCCEESS,
        data: state.data.concat(client),
      };
    }
    case EDIT_CLIENT: {
      const { client: editClient } = payload;
      return {
        ...state,
        data: state.data.map((client) => {
          if (client.id === editClient.id) {
            return editClient;
          }
          return client;
        }),
      };
    }
    case REMOVE_CLIENT: {
      const { accountRemoved } = payload;
      return {
        ...state,
        data: state.data.filter((account) => account.id !== accountRemoved.id),
      };
    }
    case LOAD_CLIENTS_SUCCESS: {
      const { clients } = payload;
      return {
        ...state,
        status: Status.SUCCEESS,
        data: clients,
      };
    }
    case LOAD_CLIENTS_IN_PROGRESS:
      return {
        ...state,
        status: Status.LOADING,
      };
    case LOAD_CLIENTS_FAILURE: {
      return {
        ...state,
        status: Status.FAILED,
        error: 'Loading clients failed',
      };
    }
    default:
      return state;
  }
};

export default clientsReducer;
