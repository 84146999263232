import { destroy, get, post } from '../../config/api';

// API Endpoints
const MEDIA_OBJECTS_URL = '/media_objects';
const PRIVATE_FILE = '/private_files';

const MediaObjects = {
  index: (): any => get(MEDIA_OBJECTS_URL),
  create: (params: FormData | undefined): any => post(MEDIA_OBJECTS_URL, params),
  remove: (id: number): any => destroy(`${MEDIA_OBJECTS_URL}/${id}`),
  single: (file: string, size?: 'thum' | 'mid'): Promise<any> =>
    get(`${PRIVATE_FILE}/${file}${size ? `?size=${size}` : ''}`, {responseType: 'blob',
      headers: {
        'Cache-Control': 'max-age=3600'
      }
    })
      .then((res: any) => new Promise((resolve) => {
          const reader = new FileReader();
          const fileName = res.headers['content-disposition'].split('filename=')[1];
          reader.onloadend = () => resolve({res: reader.result, fileName});
          reader.readAsDataURL(res.data);
        })
      )
  ,
};

export default MediaObjects;
