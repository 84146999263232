import jwt_decode from 'jwt-decode';

import Accounts from '../../services/api/Account';
import {
  NotificationService,
  Variant,
} from '../../services/NotificationService';
import { AccountEditRequest } from '../../services/request/AccountEditRequest';
import { AccountSetUserRequest } from '../../services/request/AccountSetUserRequest';
import {
  createAccount,
  editAccount,
  loadAccountsFailure,
  loadAccountsInProgress,
  loadAccountsSuccess, resetAccounts, setAccountSuccess,
} from '../actions/accounts';
import { enqueueSnackbar } from '../actions/notifications';
import LocalStorageService from '../LocalStorageServices';
import { AppDispatch } from '../store';
import {setUser} from "./shared";
import loadTeam from './teams';

export const setAccount = (data: AccountSetUserRequest) => async (
  dispatch: AppDispatch
): Promise<any> => {
  try {
    LocalStorageService.clearToken();
    dispatch(resetAccounts());
    const response = await Accounts.setUser(data);
    const setAccountData: any = await response.data;
    dispatch(createAccount(setAccountData));

    const accessTokenEncrypted = response.data.token;
    const accessToken: any = jwt_decode(accessTokenEncrypted);
    const refreshTokenEncrypted = response.data.refresh_token;
    LocalStorageService.setAccessToken(accessTokenEncrypted);
    LocalStorageService.setRefreshToken(refreshTokenEncrypted);

    const account = await Accounts.singleByEmail(accessToken.username);
    dispatch<any>(setUser(accessTokenEncrypted, account?.data[0]));

    return response;
  } catch (error: any) {
    const notification = NotificationService.create(
      `${error?.response?.data?.detail}!`,
      Variant.ERROR
    );
    dispatch(enqueueSnackbar(notification));

    return error;
  }
};

export const loadAccounts = (role: number | null = null ) => async (dispatch: AppDispatch): Promise<void> => {
  try {
    dispatch(loadAccountsInProgress());
    const accounts = await Accounts.index(role);
    dispatch(loadAccountsSuccess(accounts.data));
  } catch (e) {
    dispatch(loadAccountsFailure());
  }
};

export const editAccountRequest = (
  id: string,
  data: AccountEditRequest,
  updateAccessPermission = false,
  loadAgency: number | null = null
) => async (dispatch: AppDispatch): Promise<void> => {
  try {
    const requestInstance: AccountEditRequest = {
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      manager: data.manager,
      roles: data.roles,
      timezone: data.timezone,
      imageFormData: data.imageFormData,
      preferencies: data.preferencies,
      teams: data.teams,
      password: data.password
    };

    if (data.accountImage) requestInstance.accountImage = data.accountImage;

    const response = await Accounts.update(+id, requestInstance);
    let resp;
    if (updateAccessPermission) {
      resp = await Accounts.updatePermissions(+id,
        {
          accessRequestPermission: data.accessRequestPermission,
          clientRequestAccess: data?.clientRequestAccess
        });
    }

    const editAccountData: any = updateAccessPermission ? await resp?.data : response.data;
    dispatch(editAccount(editAccountData));
    // wait for edit to complete before load accounts.
    await new Promise((resolve) => setImmediate(resolve));
    const notification = NotificationService.create(
      'Account successfuly updated',
      Variant.INFO
    );
    dispatch(enqueueSnackbar(notification));
    if (LocalStorageService.getClientTeamId()) {
      dispatch<any>(loadTeam(Number(LocalStorageService.getClientTeamId())));
    }
    dispatch<any>(loadAccounts(loadAgency));
    dispatch(setAccountSuccess(editAccountData));
  } catch (error) {
    const notification = NotificationService.create(
      `Account update failed with error: ${error}`,
      Variant.ERROR
    );
    dispatch(enqueueSnackbar(notification));
  }
};

export const removeAccountRequest = (
  id: string,
  newAssignee: string | null,
  teamId?: number | null,
  loadAgencyRoles?: number | null) => async (
  dispatch: AppDispatch
): Promise<void> => {
  try {
    await Accounts.shallowRemove(+id, { newAssignee, status: false });
    // Wait for remove to complete before load accounts.
    await new Promise((resolve) => setImmediate(resolve));
    const notification = NotificationService.create(
      'Account removed successfully!',
      Variant.INFO
    );
    dispatch(enqueueSnackbar(notification));
    dispatch<any>(loadAccounts(loadAgencyRoles));
    if (teamId) {
      dispatch<any>(loadTeam(teamId));
    }

    return;
  } catch (error) {
    const notification = NotificationService.create(
      `Account remove failed with error:  ${error}!`,
      Variant.ERROR
    );
    dispatch(enqueueSnackbar(notification));
  }
};
