import { createSelector } from 'reselect';

import { AccountProps } from '../../containers/Agency/TeamOverview/utility';
import { Account } from '../../services/model/Account';
import { isAgencyRole, isClientRole } from '../../shared/utility';
import LocalStorageService from '../LocalStorageServices';
import { Status } from '../utillity';

export const getAccountsData = (state: Record<string, any>): AccountProps[] => state.accountsReducer.data;
export const getAgencyAccounts = (state: Record<string, any>): AccountProps[] =>
  state.accountsReducer.data.filter((account: AccountProps) => isAgencyRole(account.roles));
export const getClientRoleAccounts = (state: Record<string, any>): AccountProps[] =>
  state.accountsReducer.data.filter((account: AccountProps) => isClientRole(account.roles));
export const getAccountsLoadingStatus = (state: Record<string, any>): Status =>
  state.accountsReducer.status;
export const getSetAccountStatus = (state: Record<string, any>): Status =>
  state.setAccountReducer.status;
export const getTeamAccounts = (state: Record<string, any>): AccountProps[] =>
  state.loadSpecificAccountsReducer.data;
export const getActiveAccounts = createSelector(
  getAccountsData,
  (accounts: AccountProps[]) =>
    accounts.filter((account: AccountProps) => account.status)
);

export const getAccountById = createSelector(
  (state: Record<string, any>) => state.accountsReducer.data,
  (_: any, id: string) => id, // this is the parameter we need
  (accounts, id) =>
    accounts.find((account: Account) => String(account.id) === id)
);
export const getAccountPreferencies = createSelector(
  [getAccountById],
  (account) => account.preferencies
);

export const getAccountAccessPermission = createSelector(
  [getAccountById],
  (account) => account.accessRequestPermission
);

export const filterAccountsByClient = (state: Record<string, any>, isAdmin: boolean, clientId?: number): AccountProps[] => {

  if(clientId) {
    return state.accountsReducer.data.filter((account: AccountProps) => account.clientClientId[0]?.id === clientId);
  }

  const loggedAccountId = LocalStorageService.getLoggedAccountId() || '';
  const loggedAccountClientId = getAccountById(state, loggedAccountId)?.clientClientId[0]?.id;

  return !isAdmin ? state.accountsReducer.data.filter((account: AccountProps) =>
      loggedAccountClientId === account.clientClientId[0]?.id)
       : state.accountsReducer.data;
};
